import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'react-apollo';
import { Dropdown } from 'semantic-ui-react';

import { ListDefenderGroups } from './DefenderApi';

class DefenderGroupSelect extends Component {
  handleOnChange = (event, { value }) => {
    const { name, onChange } = this.props;

    onChange({
      target: {
        name,
        value,
      },
    });
  }

  render() {
    const { data: { __type, loading }, placeholder, value } = this.props;

    return (
      <Dropdown
        loading={loading}
        options={__type && __type.enumValues && __type.enumValues.map(enumValue => (
          { key: enumValue.name, text: enumValue.name, value: enumValue.name }
        ))}
        onChange={this.handleOnChange}
        placeholder={placeholder}
        selection
        value={value}
      />
    );
  }
}

DefenderGroupSelect.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

DefenderGroupSelect.defaultProps = {
  data: {},
  placeholder: 'Select a Group',
  value: 'defender',
};

export default graphql(ListDefenderGroups)(DefenderGroupSelect);
