import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';

import {
  Button,
  Dimmer,
  Form,
  Loader,
  Header,
  Icon,
  Table
} from 'semantic-ui-react';

import { ListCaseDefenders } from './CaseApi';
import { formatPhoneNumber } from 'react-phone-number-input';

class CaseFormDefense extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      defendersInput: []
    };
  }

  componentDidMount() {
    const {
      onChange,
      caseId,
      data: { listCaseDefenders }
    } = this.props;
    const holder = [];

    if (caseId && listCaseDefenders && listCaseDefenders.items) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of listCaseDefenders.items) {
        if (!item.active) {
          // eslint-disable-next-line no-continue
          continue;
        }
        holder.push({
          id: item.defender.id,
          firstName: item.defender.firstName,
          lastName: item.defender.lastName,
          email: item.defender.email,
          phoneNumber: item.defender.phoneNumber,
          company: item.defender.company
        });
      }
      this.setStateOnLoad(holder);
      onChange('initialDefendersInput', JSON.parse(JSON.stringify(holder)));
    } else {
      let cachedState = sessionStorage.getItem('create_case_state');
      if (cachedState) {
        cachedState = JSON.parse(cachedState);
        this.setStateOnLoad(cachedState.defendersInput);
      }
    }
  }

  setStateOnLoad(defendersInput) {
    const { onChange } = this.props;
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      defendersInput,
      isDirty: true
    });
    onChange('defendersInput', defendersInput);
  }

  componentDidUpdate() {
    const {
      onChange,
      caseId,
      data: { listCaseDefenders }
    } = this.props;
    const { defendersInput, isDirty } = this.state;
    const holder = [];
    if (
      caseId &&
      listCaseDefenders &&
      [...listCaseDefenders.items].length > 0 &&
      defendersInput.length === 0 &&
      !isDirty
    ) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of listCaseDefenders.items) {
        if (!item.active) {
          // eslint-disable-next-line no-continue
          continue;
        }
        holder.push({
          id: item.defender.id,
          firstName: item.defender.firstName,
          lastName: item.defender.lastName,
          email: item.defender.email,
          phoneNumber: item.defender.phoneNumber,
          company: item.defender.company,
          defense_role: item.defender.defense_role
        });
      }
      this.setStateOnLoad(holder);
    }
  }

  handleDeleteWitness = id => {
    const { defenders } = this.props;
    const { onChange } = this.props;
    const holder = defenders.filter(item => item.id !== id);
    this.setState({ defendersInput: holder });
    onChange('defendersInput', holder);
  };

  render() {
    const {
      defenders,
      data: { loading }
    } = this.props;
    let listCaseDefenders =
      (this.props.data.listCaseDefenders &&
        this.props.data.listCaseDefenders.items) ||
      [];
    listCaseDefenders = listCaseDefenders.sort((a, b) => {
      return `${a.defender.lastName}, ${a.defender.firstName}`.localeCompare(
        `${b.defender.lastName}, ${b.defender.firstName}`
      );
    });

    return (
      <>
        <Dimmer.Dimmable active={loading}>
          <Form className='attached fluid'>
            <Header as='h4' color='teal'>
              Defense
            </Header>
            <Table basic='very' stackable columns={6} size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Organization</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell>Role</Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: 'center' }} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {defenders &&
                  defenders.length > 0 &&
                  defenders.map(defender => (
                    <Table.Row key={defender.id}>
                      <Table.Cell>
                        {defender.lastName}, {defender.firstName}
                      </Table.Cell>
                      <Table.Cell>{defender.company || '-'}</Table.Cell>
                      <Table.Cell
                        style={{
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {defender.email}
                      </Table.Cell>
                      <Table.Cell>
                        {formatPhoneNumber(defender.phoneNumber)}
                      </Table.Cell>
                      <Table.Cell>{defender.defense_role || '-'}</Table.Cell>
                      <Table.Cell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        <Button
                          compact
                          basic
                          color='red'
                          size='small'
                          onClick={() => this.handleDeleteWitness(defender.id)}
                        >
                          <Icon name='cancel' />
                          Delete
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Form>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

CaseFormDefense.propTypes = {
  data: PropTypes.object,
  caseId: PropTypes.string,
  defenders: PropTypes.array,
  onChange: PropTypes.func.isRequired
};

CaseFormDefense.defaultProps = {
  data: {},
  caseId: null,
  defenders: []
};

export default flowRight(
  graphql(ListCaseDefenders, {
    skip: props => !props.caseId,
    options: props => ({ variables: { caseId: props.caseId } })
  })
)(CaseFormDefense);
