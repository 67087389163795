import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";
import { navigate } from "../services/RouteState";
import { withApollo } from "react-apollo";
import { Dropdown, Menu, Icon, Modal, Button } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import burger from "../images/burger.png";
import {
  getUserAttributes,
  isAuthenticated,
  signOut,
} from "../services/Authentication";

const COUNTY_MAPPING = {
  "witcom-io": "WitCom Testing District Attorney",
  "kings-ny": "Kings County District Attorney",
  "newyork-ny": "New York County District Attorney",
  "kings-ny-test": "Kings County District Attorney",
  "newyork-ny-test": "New York County District Attorney",
};

class Header extends Component {
  state = {
    authenticated: false,
    email: "",
  };

  async componentDidMount() {
    try {
      await isAuthenticated();
      const attributes = await getUserAttributes();

      this.setState({
        authenticated: true,
        email: attributes.email,
        jurisdiction: attributes.jurisdiction,
        open: false,
      });
    } catch (error) {
      // nothing
    }
  }

  handleSubmit = async () => {
    const { client } = this.props;

    try {
      await client.resetStore();

      await signOut();

      this.setState({
        authenticated: false,
        email: "",
      });

      navigate("/login");
    } catch (error) {
      // nothing
    }
  };

  handleContact = () => {
    window.open("mailto: support@witcom.io");
  };

  closeModal() {
    this.setState({ open: false });
    this.modalContent = "";
  }

  handleClick = () => {
    const { onChange } = this.props;
    onChange();
  };

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  render() {
    const { authenticated, email, jurisdiction } = this.state;
    const { width } = this.props;

    return (
      <>
        <Modal size="large" open={this.state.open}>
          <Modal.Content className="docmodal">
            <ReactMarkdown source={this.modalContent} />
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={() => this.closeModal()}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Menu
          borderless
          secondary
          fixed="top"
          style={{
            background: "#fff",
            color: "teal",
            left: `${width}px`,
            width: `calc(100% - ${width}px)`,
            transition: "left 0.5s, width 0.5s",
          }}
        >
          <div>
            <img
              style={{
                cursor: "pointer",
                paddingTop: "3px",
                position: "relative",
                float: "left",
              }}
              as="a"
              src={burger}
              alt=""
              onClick={this.handleClick}
            />
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: "0px",
              marginTop: "17px",
              color: "black",
              textAlign: "center",
              transition: "width 0.5s",
            }}
          >
            <span style={{ fontSize: "15px" }}>
              {COUNTY_MAPPING[jurisdiction]}
            </span>
          </div>

          {authenticated ? (
            <Dropdown
              item
              trigger={
                <span>
                  <Icon style={{ fontSize: "1.5em" }} name="setting" />
                </span>
              }
              floating
              style={{ color: "rgba(0,0,0,0.85)" }}
            >
              <Dropdown.Menu style={{ left: "auto", right: "0" }}>
                <Dropdown.Header
                  content={email}
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textTransform: "none",
                  }}
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  icon="mail"
                  text="Contact Support"
                  onClick={this.handleContact.bind(this)}
                />
                <Dropdown.Divider />

                <Dropdown.Item
                  icon="file alternate"
                  text="Privacy Policy"
                  as="a"
                  target="_blank"
                  href="https://witcom.io/privacy-policy/index.html"
                />
                <Dropdown.Divider />

                <Dropdown.Item
                  icon="file alternate outline"
                  text="Terms"
                  as="a"
                  target="_blank"
                  href="https://witcom.io/terms-of-service/index.html"
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  icon="sign-out"
                  text="Log Out"
                  onClick={this.handleSubmit}
                />
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item as={Link} position="right" to="/login">
              Log in
            </Menu.Item>
          )}
        </Menu>
      </>
    );
  }
}

Header.propTypes = {
  client: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withApollo(Header);
