import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { buildSubscription } from 'aws-appsync';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';
import { Dimmer, Loader, Message, Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { formatPhoneNumber } from 'react-phone-number-input';
import { ListWitnesses, OnWitnessSubscription } from './WitnessApi';

class WitnessTable extends Component {
  componentDidMount() {
    const {
      caseId,
      data: { subscribeToMore }
    } = this.props;

    subscribeToMore(
      buildSubscription(
        { query: OnWitnessSubscription, variables: { caseId } },
        ListWitnesses
      )
    );
  }

  upperCase = string => string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const {
      data: { error, loading }
    } = this.props;
    let listWitnesses = this.props.data.listWitnesses && this.props.data.listWitnesses.items || []
    listWitnesses = listWitnesses.sort((a, b) => {
      return `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`)
    })
    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        <Dimmer.Dimmable active={loading}>
          <Table basic='very' column={7} fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Trial Witness</Table.HeaderCell>
                <Table.HeaderCell>Added to Case</Table.HeaderCell>
                <Table.HeaderCell>Primary Language</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Phone Type</Table.HeaderCell>
                <Table.HeaderCell>Preferred Contact Method</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listWitnesses && listWitnesses.length > 0 ? (
                [...listWitnesses].map(item => (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {item.lastName}, {item.firstName}
                    </Table.Cell>
                    <Table.Cell>
                      {(item.trialWitness === 'yes' ? (
                        <Icon name='check' color='green' />
                      ) : null) ||
                        (item.trialWitness === 'no' ? (
                          <Icon name='cancel' color='red' />
                        ) : null)}
                    </Table.Cell>
                    <Table.Cell>
                      {item.createdAt &&
                        moment(item.createdAt).format('MM/DD/YYYY')}
                    </Table.Cell>
                    <Table.Cell>{item.language}</Table.Cell>
                    <Table.Cell>
                      {formatPhoneNumber(item.phoneNumbers[0].number)}
                    </Table.Cell>
                    <Table.Cell>
                      {this.upperCase(item.phoneNumbers[0].type)}
                    </Table.Cell>
                    <Table.Cell>
                      {this.upperCase(item.preferredContactMethod)}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                  <Table.Row>
                    <Table.Cell colSpan='7' textAlign='center'>
                      {' '}
                      No witnesses available
                    {' '}
                    </Table.Cell>
                  </Table.Row>
                )}
            </Table.Body>
          </Table>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

WitnessTable.propTypes = {
  caseId: PropTypes.string.isRequired,
  data: PropTypes.object
};

WitnessTable.defaultProps = {
  data: {}
};

export default flowRight(
  graphql(ListWitnesses, {
    options: props => ({
      variables: {
        caseId: props.caseId
      }
    })
  })
)(WitnessTable);
