import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { graphqlMutation } from 'aws-appsync-react';
import { navigate } from '../../services/RouteState';
import {
  Breadcrumb,
  Divider,
  Grid,
  Segment,
  Button,
  Icon,
} from 'semantic-ui-react';
import ProsecutorHeader from './ProsecutorHeader';
import IdentityTable from '../identity/identityTable';
import DeleteModal from '../modal/DeleteModal';
import { DisableProsecutor, EnableProsecutor } from './ProsecutorApi';
import ConfirmModal from '../modal/ConfirmModal';
import { getUserGroup, getUserAttributes } from '../../services/Authentication';

class ProsecutorView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      isAdmin: false,
      currentProsecutorId: '',
    };
  }

  async componentWillMount() {
    const role = await getUserGroup();
    const currentProsecutor = await getUserAttributes();
    this.setState({
      isAdmin: role === 'admin',
      currentProsecutorId: currentProsecutor.sub,
    });
  }

  handleOnClick = prosecutorId => {
    navigate('/app/prosecutor/edit', {
      state: { prosecutorId },
    });
  };

  handleDelete = async id => {
    // Delete Case
    const {
      disableProsecutor,
      location: {
        state: { prosecutorId, enabled },
      },
    } = this.props;

    this.setState({
      loading: true,
      error: null,
    });

    try {
      await disableProsecutor({ id });
      navigate('/app/prosecutor/view', {
        state: { prosecutorId, enabled: !enabled },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  handleEnable = async id => {
    // Delete Case
    const {
      enableProsecutor,
      location: {
        state: { prosecutorId, enabled },
      },
    } = this.props;
    this.setState({
      loading: true,
    });
    try {
      await enableProsecutor({ id });
      navigate('/app/prosecutor/view', {
        state: { prosecutorId, enabled: !enabled },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const {
      location: {
        state: { prosecutorId, enabled },
      },
    } = this.props;
    const { loading, error, isAdmin, currentProsecutorId } = this.state;
    const sameUser = prosecutorId === currentProsecutorId;

    console.log('isAdmin', isAdmin);
    return (
      <>
        <Grid columns="equal" relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Breadcrumb size="huge">
                <Breadcrumb.Section active>
                  Prosecutor Information
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign="right">
              {isAdmin && (
                <Button
                  onClick={() => this.handleOnClick(prosecutorId)}
                  color="blue"
                  compact
                  size="compact"
                  disabled={false}
                >
                  {/* <Icon color='white' name='edit' /> */}
                  Edit Account
                </Button>
              )}
              {isAdmin && enabled && !sameUser && (
                <DeleteModal
                  title="Disabling Prosecutor"
                  name="Disable"
                  color="red"
                  content="You are about to disable this prosecutor. Do you want to continue?"
                  onChangePositive={() => this.handleDelete(prosecutorId)}
                  disabled={false}
                  loading={loading}
                />
              )}
              {isAdmin && !enabled && (
                <ConfirmModal
                  type={'Enable'}
                  title="Enabling Prosecutor"
                  loading={loading}
                  disabled={false}
                  // content="You are about to send the victim/witness list to the defense. If you cancel, the case data will not be saved. Do you wish to proceed?"
                  content="You are about to enable this prosecutor. Do you want to continue?"
                  isDirty={false}
                  onChangePositive={() => this.handleEnable(prosecutorId)}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <ProsecutorHeader prosecutorId={prosecutorId} />
        <Divider hidden />
        <Segment>
          <IdentityTable prosecutorId={prosecutorId} />
        </Segment>
      </>
    );
  }
}

ProsecutorView.propTypes = {
  location: PropTypes.object,
  disableProsecutor: PropTypes.func.isRequired,
};

ProsecutorView.defaultProps = {
  location: {},
};

export default flowRight(
  graphqlMutation(DisableProsecutor),
  graphqlMutation(EnableProsecutor)
)(ProsecutorView);
