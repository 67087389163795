import React, { Component } from 'react';

import {
  Container, Dimmer, Icon, Loader, Message, Table,
} from 'semantic-ui-react';

import { getUserAttributes } from '../../services/Authentication';


class ProfileDetail extends Component {
  state = {
    attributes: {},
    errorMessage: '',
    loading: false,
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    try {
      const attributes = await getUserAttributes();

      this.setState({
        loading: false,
        attributes,
      });
    } catch (error) {
      this.setState({
        loading: false,
        errorMessage: error.message,
      });
    }
  }

  handlePhoneChange = (index, phone) => {
    const { attributes } = this.state;
    const holder = attributes;
    holder.phone_number = phone;
    this.setState({
      attributes: holder,
    });
  }

  render() {
    const {
      attributes, errorMessage, loading,
    } = this.state;

    return (
      <>
        {errorMessage ? (
          <Message negative>
            <p>{errorMessage}</p>
          </Message>
        ) : (
          <Container>
            <Dimmer.Dimmable dimmed={loading}>
              {/* <List>
                <List.Item>sub: {attributes.sub}</List.Item>
                <List.Item>email: {attributes.email}</List.Item>
                <List.Item>given_name: {attributes.given_name}</List.Item>
                <List.Item>family_name: {attributes.family_name}</List.Item>
                <List.Item>phone_number: {attributes.phone_number}</List.Item>
                <List.Item>
                  token: {token}
                </List.Item>
              </List> */}
              <Table celled striped>
                <Table.Header>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="user" /> First Name
                    </Table.Cell>
                    <Table.Cell>{attributes.given_name}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="user" /> Last Name
                    </Table.Cell>
                    <Table.Cell>{attributes.family_name}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Icon name="mail" /> Email Address
                    </Table.Cell>
                    <Table.Cell>{attributes.email}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Icon name="phone" /> Phone Number
                    </Table.Cell>
                    <Table.Cell>{attributes.phone_number}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Dimmer active={loading} inverted>
                <Loader />
              </Dimmer>
            </Dimmer.Dimmable>
          </Container>
        )}
      </>
    );
  }
}

export default ProfileDetail;
