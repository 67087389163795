import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { buildSubscription } from 'aws-appsync';
import moment from 'moment';
import { graphql } from 'react-apollo';
import {
  Dimmer,
  Icon,
  Grid,
  Header,
  Loader,
  Message,
  Divider,
  Segment
} from 'semantic-ui-react';

import { GetProsecutor, OnProsecutorSubscription } from './ProsecutorApi';
import { formatPhoneNumber } from 'react-phone-number-input';

class ProsecutorHeader extends Component {
  componentDidMount() {
    const {
      data: { subscribeToMore }
    } = this.props;

    subscribeToMore(buildSubscription(OnProsecutorSubscription, GetProsecutor));
  }

  upperCase = string => string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const {
      data: { error, loading, getProsecutor }
    } = this.props;

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        {getProsecutor && (
          <div style={{ backgroundColor: '#fff' }}>
            <Segment>
              <Dimmer.Dimmable dimmed={loading}>
                <Grid columns='equal' relaxed stretched stackable divided>
                  <Grid.Row>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {`${getProsecutor.lastName}, ${getProsecutor.firstName}`}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='linkify' />
                            Name
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {getProsecutor.email}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='mail' />
                            Email
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {formatPhoneNumber(getProsecutor.phoneNumber)}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='phone' />
                            Phone
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {moment(getProsecutor.createdAt).format('MM/DD/YYYY')}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='clock outline' />
                            User Start Date
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {this.upperCase(getProsecutor.group)}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='user circle' />
                            Account Type
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {getProsecutor.enabled === true
                            ? getProsecutor.status === 'FORCE_CHANGE_PASSWORD'
                              ? 'Registered, Not Confirmed'
                              : 'Active'
                            : 'Disabled'}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='settings' />
                            Account Status
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Dimmer active={loading} inverted>
                  <Loader />
                </Dimmer>
              </Dimmer.Dimmable>
            </Segment>
          </div>
        )}
      </>
    );
  }
}

ProsecutorHeader.propTypes = {
  data: PropTypes.object
};

ProsecutorHeader.defaultProps = {
  data: {}
};

export default graphql(GetProsecutor, {
  skip: props => !props.prosecutorId,
  options: props => ({
    variables: {
      id: props.prosecutorId
    }
  })
})(ProsecutorHeader);
