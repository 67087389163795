import React, { Component } from 'react';

import { navigate } from '../../services/RouteState';
import { Breadcrumb, Button, Grid, Segment } from 'semantic-ui-react';

import ProsecutorTable from './ProsecutorTable';
import { getUserAttributes } from '../../services/Authentication';
class Prosecutors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: null
    };
  }

  async componentDidMount() {
    const attributes = await getUserAttributes();
    this.setState({ jurisdiction: attributes.jurisdiction });
  }

  handleOnClick = () => {
    navigate('/app/prosecutor/edit');
  };

  render() {
    const { jurisdiction } = this.state;
    return (
      <>
        <Grid columns='equal' relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Breadcrumb size='huge'>
                <Breadcrumb.Section active>Prosecutor List</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ProsecutorTable jurisdiction={jurisdiction} />
      </>
    );
  }
}

export default Prosecutors;
