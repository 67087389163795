import React, { Component } from "react";
import PropTypes from "prop-types";

import { graphqlMutation } from "aws-appsync-react";
import { navigate } from "../../services/RouteState";
import { graphql } from "react-apollo";
import { flowRight } from "lodash";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  Breadcrumb,
  Button,
  Dimmer,
  Divider,
  Grid,
  Form,
  Loader,
  Message,
  Segment,
  Text,
  Dropdown,
  Label
} from "semantic-ui-react";

import DefenderGroupSelect from "./DefenderGroupSelect";
import { GetDefender, CreateDefender, UpdateDefender } from "./DefenderApi";
import ConfirmModal from "../modal/ConfirmModal";
import { errors, validate } from "../models/FieldValidator.js";
import { getUserAttributes } from "../../services/Authentication";

const styles = {
  errorText: {
    color: "red",
    fontSize: "11px",
  },
};

const defenseOptions = [
  {
    text: 'Defense Attorney',
    value: 'Attorney'
  },
  {
    text: 'Defense Investigator',
    value: 'Investigator'
  },
]

class DefenderForm extends Component {
  // errorMessage;
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      isDirty: false,
      loading: false,
      error: null,
      defenderInput: {
        group: "defender",
      },
    };
  }

  async componentDidMount() {
    const {
      data: { getDefender },
    } = this.props;

    const attributes = await getUserAttributes();
    this.setState({
      jurisdiction: attributes.jurisdiction,
    });

    if (getDefender) {
      this.setState({
        edit: true,
        defenderInput: getDefender,
      });
    }
  }

  componentDidUpdate() {
    const {
      data: { getDefender },
      location: {
        state: { defenderId },
      },
    } = this.props;
    const { defenderInput } = this.state;
    if (defenderId && getDefender && [...getDefender.id] && !defenderInput.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        defenderInput: getDefender,
      });
    }
  }

  handleOnChange = (event, data) => {
    const { defenderInput } = this.state;
    defenderInput[data.name] = data.value;
    this.setState({
      defenderInput,
      isDirty: true,
    });
  };

  handlePhoneChange = (phone, name) => {
    const { defenderInput } = this.state;

    defenderInput[name] = phone;

    this.setState({
      defenderInput,
      isDirty: true,
    });
  };

  handleCancel = () => {
    navigate("/app/defender");
  };

  handleSubmit = async () => {
    const { edit, defenderInput, jurisdiction } = this.state;
    const { createDefender, updateDefender } = this.props;
    this.setState({
      loading: true,
    });
    let response = null;
    defenderInput.company = defenderInput.company || "";
    if (
      defenderInput.email &&
      defenderInput.firstName &&
      defenderInput.group &&
      defenderInput.lastName &&
      defenderInput.phoneNumber &&
      defenderInput.defense_role
    ) {
      try {
        if (edit) {
          response = await updateDefender({
            id: defenderInput.id,
            defender: {
              email: defenderInput.email.toLowerCase(),
              firstName: defenderInput.firstName,
              group: defenderInput.group,
              lastName: defenderInput.lastName,
              phoneNumber: defenderInput.phoneNumber,
              company: defenderInput.company,
              defense_role: defenderInput.defense_role,
              jurisdiction,
            },
          });
        } else {
          response = await createDefender({
            defender: {
              email: defenderInput.email.toLowerCase(),
              firstName: defenderInput.firstName,
              group: defenderInput.group,
              lastName: defenderInput.lastName,
              phoneNumber: defenderInput.phoneNumber,
              company: defenderInput.company,
              defense_role: defenderInput.defense_role,
              jurisdiction,
            },
          });
        }
        if (response.errors) {
          console.log("response", response);
          this.setState({
            error: response.errors[0].message,
            loading: false,
          });
          return;
        }

        const data =
          response.data.updateDefender || response.data.createDefender;
        const params = {
          state: {
            active: edit ? defenderInput.enabled : true,
            defenderId: data.id,
          },
        };
        console.log("navigating to defender view");
        navigate("/app/defender/view", params);
      } catch (errors) {
        console.log("Errors", errors);
        this.setState({
          loading: false,
          error:
            response.errors[0].message ===
            "Attribute value for custom:proxy must not be null"
              ? `Unable to register defense attorney.  New WitCom proxy numbers are not available.  
              Please contact WitCom support at support@witcom.io.`
              : response.errors[0].message,
        });
      }
    } else {
      this.setState({
        loading: false,
        error: "Please fill out all fields",
      });
    }
  };

  render() {
    const { edit, error, defenderInput, loading, isDirty } = this.state;
    return (
      <>
        <Grid columns="equal" relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Breadcrumb size="huge">
                {/* <Breadcrumb.Section
                        link
                        onChangePositive
                        onClick={() => navigate('/app/defender')}>
                        Defense
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider /> */}
                <Breadcrumb.Section active>
                  {edit
                    ? "Edit Defense Information"
                    : "Register Defense"}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <ConfirmModal
                type="Cancel"
                title="Unsaved Changes"
                content={
                  "You are about to exit with unsaved changes.\xa0 Do you wish to proceed without saving?"
                }
                isDirty={isDirty}
                loading={loading}
                onChangePositive={() => this.handleCancel()}
                disabled={false}
                size="small"
              />
              <ConfirmModal
                type="Submit"
                title={
                  edit ? "Update Defense Attorney" : "Save Defense Attorney"
                }
                content={
                  edit
                    ? `You are about to update this defense attorney's information.\xa0 Do you wish to proceed?`
                    : `You are about to register a new defense attorney.\xa0 Do you wish to proceed?`
                }
                loading={loading}
                size="small"
                disabled={
                  !defenderInput.email ||
                  !validate("email", defenderInput.email) ||
                  !defenderInput.firstName ||
                  defenderInput.firstName.length < 2 ||
                  !defenderInput.lastName ||
                  defenderInput.lastName.length < 2 ||
                  !defenderInput.phoneNumber ||
                  !validate("phoneNumber", defenderInput.phoneNumber) ||
                  !defenderInput.group
                }
                isDirty={isDirty}
                onChangePositive={() => this.handleSubmit("active")}
              />
              <div style={{ paddingBottom: "25px" }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Dimmer.Dimmable active={loading}>
          <Segment>
            <Form
              autoComplete="off"
              className="attached fluid"
              loading={loading}
            >
              {error && (
                <Message negative>
                  <p>{error}</p>
                </Message>
              )}
              <Form.Field autoComplete="off">
                <Form.Input
                  label="First Name *"
                  autoComplete="off"
                  name="firstName"
                  onChange={this.handleOnChange}
                  placeholder="First Name"
                  value={defenderInput.firstName}
                  error={
                    defenderInput.firstName ? (
                      !validate("firstName", defenderInput.firstName) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <text>{errors["firstName"].message}</text>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  label="Last Name *"
                  autoComplete="off"
                  name="lastName"
                  onChange={this.handleOnChange}
                  placeholder="Last Name"
                  value={defenderInput.lastName}
                  error={
                    defenderInput.lastName ? (
                      !validate("lastName", defenderInput.lastName) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <text>{errors["lastName"].message}</text>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                {/* <Form.Input
                  label="Defense Role"
                  autoComplete="off"
                  name="defense_role"
                  onChange={this.handleOnChange}
                  placeholder="Defense Role"
                  value={defenderInput.defense_role}
                /> */}
                <span style={{fontSize:'12.6px'}}><b>Role *</b></span>
                 <Dropdown
                  label="Role"
                  placeholder='Select a Role'
                  fluid
                  selection
                  onChange={this.handleOnChange}
                  name="defense_role"
                  value={defenderInput.defense_role}
                  options={defenseOptions}
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  label="Organization"
                  autoComplete="off"
                  name="company"
                  onChange={this.handleOnChange}
                  placeholder="Organization"
                  value={defenderInput.company}
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  label="Email *"
                  autoComplete="off"
                  name="email"
                  value={defenderInput.email}
                  // onChange={value =>
                  //   this.isValidField(name, value)
                  // }
                  onChange={this.handleOnChange}
                  disabled={edit ? true : false}
                  placeholder="Email"
                  error={
                    defenderInput.email ? (
                      validate("email", defenderInput.email) ? undefined : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <text>{errors["email"].message}</text>
                        </span>
                      )
                    ) : undefined
                  }
                />
              </Form.Field>
              <Form.Field>
                {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                {defenderInput.phoneNumber &&
                !validate("phoneNumber", defenderInput.phoneNumber) ? (
                  <label style={{ color: "#9F3A38" }}>Phone Number *</label>
                ) : (
                  <label style={{ color: "rgba(0,0,0,0.87)" }}>
                    Phone Number *
                  </label>
                )}
                <PhoneInput
                  placeholder="Phone number"
                  autoComplete="off"
                  value={defenderInput.phoneNumber}
                  name="phoneNumber"
                  onChange={(phone) =>
                    this.handlePhoneChange(phone, "phoneNumber")
                  }
                  showCountrySelect={false}
                  country="US"
                  // eslint-disable-next-line no-nested-ternary
                  error={
                    defenderInput.phoneNumber ? (
                      validate(
                        "phoneNumber",
                        defenderInput.phoneNumber
                      ) ? undefined : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <text>{errors["phoneNumber"].message}</text>
                        </span>
                      )
                    ) : undefined
                  }
                />
              </Form.Field>
              {/* <Form.Field autoComplete="off">
                                <label>Group</label>
                                <DefenderGroupSelect
                                    name="group"
                                    onChange={this.handleOnChange}
                                    value={defenderInput.group}
                                />
                            </Form.Field> */}
            </Form>
          </Segment>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

DefenderForm.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  createDefender: PropTypes.func.isRequired,
  updateDefender: PropTypes.func.isRequired,
};

DefenderForm.defaultProps = {
  data: {},
  location: {},
};

export default flowRight(
  graphql(GetDefender, {
    skip: (props) => {
      const {
        location: { state: { defenderId } = {} },
      } = props;
      return !defenderId;
    },
    options: (props) => {
      const {
        location: { state: { defenderId } = {} },
      } = props;
      return {
        variables: {
          id: defenderId,
        },
      };
    },
  }),
  graphqlMutation(CreateDefender),
  graphqlMutation(UpdateDefender)
)(DefenderForm);
