import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';

import {
  Button,
  Dimmer,
  Form,
  Loader,
  Header,
  Table,
  Icon
} from 'semantic-ui-react';

import { ListCaseProsecutors } from './CaseApi';
import { formatPhoneNumber } from 'react-phone-number-input';
import { getUserAttributes } from '../../services/Authentication';

class CaseFormProsecutor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      prosecutorsInput: []
    };
  }

  componentDidMount() {
    const {
      caseId,
      data: { listCaseProsecutors }
    } = this.props;
    const holder = [];

    if (caseId && listCaseProsecutors && listCaseProsecutors.items) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of listCaseProsecutors.items) {
        if (!item.active) {
          // eslint-disable-next-line no-continue
          continue;
        }
        holder.push({
          id: item.prosecutor.id,
          active: item.active,
          firstName: item.prosecutor.firstName,
          lastName: item.prosecutor.lastName,
          email: item.prosecutor.email,
          phoneNumber: item.prosecutor.phoneNumber
        });
      }
      this.setStateOnLoad(holder);
    } else {
      let cachedState = sessionStorage.getItem('create_case_state');
      if (cachedState) {
        cachedState = JSON.parse(cachedState);
        this.setStateOnLoad(cachedState.prosecutorsInput);
      }
    }
  }

  componentDidUpdate() {
    const {
      caseId,
      data: { listCaseProsecutors }
    } = this.props;
    const { prosecutorsInput, isDirty } = this.state;
    const holder = [];

    if (
      caseId &&
      listCaseProsecutors &&
      [...listCaseProsecutors.items].length > 0 &&
      prosecutorsInput.length === 0 &&
      !isDirty
    ) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of listCaseProsecutors.items) {
        if (!item.active) {
          // eslint-disable-next-line no-continue
          continue;
        }
        holder.push({
          id: item.prosecutor.id,
          active: item.active,
          firstName: item.prosecutor.firstName,
          lastName: item.prosecutor.lastName,
          email: item.prosecutor.email,
          phoneNumber: item.prosecutor.phoneNumber
        });
      }
      this.setStateOnLoad(holder);
    }
  }

  setStateOnLoad(prosecutorsInput) {
    const { onChange } = this.props;
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      isDirty: true,
      prosecutorsInput
    });
    onChange('prosecutorsInput', prosecutorsInput);
  }

  handleDeleteProsecutor = id => {
    const { prosecutors } = this.props;
    const { onChange } = this.props;
    const holder = prosecutors.filter(item => item.id !== id);
    this.setState({ prosecutorsInput: holder });
    onChange('prosecutorsInput', holder);
  };

  render() {
    const {
      prosecutors,
      data: { loading }
    } = this.props;
    let listCaseProsecutors =
      (this.props.data.listCaseProsecutors &&
        this.props.data.listCaseProsecutors.items) ||
      [];
    listCaseProsecutors = listCaseProsecutors.sort((a, b) => {
      return `${a.prosecutor.lastName}, ${a.prosecutor.firstName}`.localeCompare(
        `${b.prosecutor.lastName}, ${b.prosecutor.firstName}`
      );
    });
    return (
      <>
        <Dimmer.Dimmable active={loading}>
          <Form className='attached fluid'>
            <Header as='h4' color='teal'>
              Prosecutors *
            </Header>
            <Table basic='very' stackable columns={4} size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: 'center' }} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {prosecutors &&
                  prosecutors.length > 0 &&
                  prosecutors.map(prosecutor => (
                    <Table.Row key={prosecutor.id}>
                      <Table.Cell>
                        {prosecutor.lastName}
                        {', '}
                        {prosecutor.firstName}
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {prosecutor.email}
                      </Table.Cell>
                      <Table.Cell>
                        {formatPhoneNumber(prosecutor.phoneNumber)}
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        <Button
                          compact
                          basic
                          color='red'
                          size='small'
                          onClick={() =>
                            this.handleDeleteProsecutor(prosecutor.id)
                          }
                        >
                          <Icon name='cancel' />
                          Delete
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Form>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

CaseFormProsecutor.propTypes = {
  data: PropTypes.object,
  caseId: PropTypes.string,
  prosecutors: PropTypes.array,
  onChange: PropTypes.func.isRequired
};

CaseFormProsecutor.defaultProps = {
  data: {},
  caseId: null,
  prosecutors: []
};

export default flowRight(
  graphql(ListCaseProsecutors, {
    skip: props => !props.caseId,
    options: props => ({ variables: { caseId: props.caseId } })
  })
)(CaseFormProsecutor);
