import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'semantic-ui-react';

const languages = [
    { key: 'Arabic', text: 'Arabic', value: 'Arabic' },
    { key: 'Cantonese', text: 'Chinese - Cantonese', value: 'Chinese - Cantonese' },
    { key: 'Mandarin', text: 'Chinese - Mandarin', value: 'Chinese - Mandarin' },
    { key: 'Danish', text: 'Danish', value: 'Danish' },
    { key: 'Dutch', text: 'Dutch', value: 'Dutch' },
    { key: 'English', text: 'English', value: 'English' },
    { key: 'French', text: 'French', value: 'French' },
    { key: 'German', text: 'German', value: 'German' },
    { key: 'Greek', text: 'Greek', value: 'Greek' },
    { key: 'Hungarian', text: 'Hungarian', value: 'Hungarian' },
    { key: 'Italian', text: 'Italian', value: 'Italian' },
    { key: 'Japanese', text: 'Japanese', value: 'Japanese' },
    { key: 'Korean', text: 'Korean', value: 'Korean' },
    { key: 'Lithuanian', text: 'Lithuanian', value: 'Lithuanian' },
    { key: 'Persian', text: 'Persian', value: 'Persian' },
    { key: 'Polish', text: 'Polish', value: 'Polish' },
    { key: 'Portuguese', text: 'Portuguese', value: 'Portuguese' },
    { key: 'Russian', text: 'Russian', value: 'Russian' },
    { key: 'Spanish', text: 'Spanish', value: 'Spanish' },
    { key: 'Swedish', text: 'Swedish', value: 'Swedish' },
    { key: 'Turkish', text: 'Turkish', value: 'Turkish' },
    { key: 'Vietnamese', text: 'Vietnamese', value: 'Vietnamese' }
];

class LanguageDropdown extends Component {
    handleOnChange = (event, { value }) => {
        const { name, onChange } = this.props;

        onChange({
            target: {
                name,
                value
            }
        });
    };

    render() {
        const { placeholder, value } = this.props;

        return (
            <Dropdown
                size='small'
                options={languages}
                onChange={this.handleOnChange}
                placeholder={placeholder}
                selection
                fluid
                value={value}
            />
        );
    }
}

LanguageDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

LanguageDropdown.defaultProps = {
    placeholder: 'Language',
    value: 'English'
};

export default LanguageDropdown;
