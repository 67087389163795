import * as Axios from "axios";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { flowRight } from "lodash";
import { buildSubscription } from "aws-appsync";
import { graphqlMutation } from "aws-appsync-react";
import { graphql } from "react-apollo";
import { navigate } from "../../services/RouteState";
import { API } from "../../services/RestAPI";
import {
  Breadcrumb,
  Divider,
  Grid,
  Segment,
  Button,
  Message,
  Icon,
} from "semantic-ui-react";
import DefenderHeader from "./DefenderHeader";
import IdentityTable from "../identity/identityTable";
import DeleteModal from "../modal/DeleteModal";
import ConfirmModal from "../modal/ConfirmModal";
import {
  GetDefender,
  EnableDefender,
  DisableDefender,
  OnDefenderSubscription,
} from "./DefenderApi";
import { getUserGroup } from "../../services/Authentication";

class DefenderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAdmin: false,
      token: Math.random().toString(),
    };
  }

  async componentWillMount() {
    const role = await getUserGroup();
    const { query } = this.props.client;
    const { defenderId } = this.props.location.state;
    let res = await query({
      query: GetDefender,
      variables: {
        id: defenderId,
      },
    });
    console.log("get defender response:", res);
    this.setState({
      isAdmin: role === "admin",
      email: res.data.getDefender.email,
    });
    await API.init();
  }

  handleOnClick = (defenderId) => {
    navigate("/app/defender/edit", {
      state: { defenderId },
    });
  };

  handleDelete = async (id) => {
    // Delete Case
    const {
      disableDefender,
      location: {
        state: { defenderId, active },
      },
    } = this.props;
    this.setState({
      loading: true,
    });
    try {
      await disableDefender({ id });
      navigate("/app/defender/view", {
        state: { defenderId, active: !active },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  handleEnable = async (id) => {
    // Delete Case
    const {
      enableDefender,
      location: {
        state: { defenderId, active },
      },
    } = this.props;
    this.setState({
      loading: true,
    });
    try {
      await enableDefender({ id });
      navigate("/app/defender/view", {
        state: { defenderId, active: !active },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  handleChangeNumber = async (email) => {
    this.setState({ loading: true });
    const active = this.props.location.state.active;
    const defenderId = this.props.location.state.defenderId;

    try {
      const res = await API.Proxy.reset(email);
      console.log(`reset proxy result: ${res}`);
      const params = { state: { defenderId, active } };
      navigate("/app/defender/view", params);
    } catch (error) {
      this.setState({
        error: error,
      });
    } finally {
      this.setState({
        loading: false,
        token: Math.random().toString(),
      });
    }
  };

  render() {
    const {
      location: {
        state: { defenderId, active },
      },
    } = this.props;
    const { loading, isAdmin, email, token, error } = this.state;

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.response.data}</p>
          </Message>
        )}
        <Grid columns="equal" relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Breadcrumb size="huge">
                <Breadcrumb.Section active>
                  Defense Information
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign="right">
              {
                <Button
                  onClick={() => this.handleOnClick(defenderId)}
                  color="blue"
                  size="small"
                  disabled={false}
                >
                  {/* <Icon color='white' name='edit' /> */}
                  Edit Account
                </Button>
              }
              {/* {isAdmin && (
                <ConfirmModal
                  type={"Change WitCom Number"}
                  title="Changing Defense Attorney's WitCom Number"
                  loading={loading}
                  disabled={false}
                  content="You are about to change the WitCom Number of this defense attorney. 
                  If you confirm these changes, all witnesses connected to this defense attorney
                   will be sent updated VCARD attachments. Do you wish to continue?"
                  isDirty={false}
                  onChangePositive={() => this.handleChangeNumber(email)}
                />
              )} */}
              {isAdmin && active && (
                <DeleteModal
                  title="Disabling Defense Attorney"
                  name="Disable"
                  color="red"
                  content="You are about to disable this defense attorney. Do you want to continue?"
                  onChangePositive={() => this.handleDelete(defenderId)}
                  disabled={false}
                  loading={loading}
                />
              )}
              {isAdmin && !active && (
                <ConfirmModal
                  type={"Enable"}
                  title="Enabling Defense Attorney"
                  loading={loading}
                  disabled={false}
                  // content="You are about to send the victim/witness list to the defense. If you cancel, the case data will not be saved. Do you wish to proceed?"
                  content="You are about to enable this defense attorney. Do you want to continue?"
                  isDirty={false}
                  // Need to add proxy reset handler and switch out handleEnable in onChangePositive
                  onChangePositive={() => this.handleEnable(defenderId)}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <DefenderHeader defenderId={defenderId} token={token} />
        <Divider hidden />
        <Segment>
          <IdentityTable defenderId={defenderId} />
        </Segment>
      </>
    );
  }
}

DefenderView.propTypes = {
  location: PropTypes.object,
  disableDefender: PropTypes.func.isRequired,
};

DefenderView.defaultProps = {
  location: {},
};

export default flowRight(
  graphql(GetDefender, {
    options: (props) => ({
      variables: {
        id: props.defenderId,
      },
    }),
  }),
  graphqlMutation(DisableDefender),
  graphqlMutation(EnableDefender)
)(DefenderView);
