import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { navigate } from '../../services/RouteState';
import { Button, Form, Message, Input, Icon } from 'semantic-ui-react';

import { authenticateUser } from '../../services/Authentication';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      error: null,
      newPasswordIcon: 'eye slash',
      hideNewPassword: true,
      confirmPasswordIcon: 'eye slash',
      hideConfirmPassword: true,

    };
    // bind function to component
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    // when component mounts, add a document listener for keydown
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentWillUnmount() {
    // when component unmounts, remove document listener so there aren't any accidental fires in other components
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  changeNewPasswordIcon() {
    this.setState(prevState => ({
      icon: prevState.newPasswordIcon === 'eye slash' ? 'eye' : 'eye slash',
      hideNewPassword: !prevState.hideNewPassword
    }));
  }

  changeConfirmPasswordIcon() {
    this.setState(prevState => ({
      icon: prevState.confirmPasswordIcon === 'eye slash' ? 'eye' : 'eye slash',
      hideConfirmPassword: !prevState.hideConfirmPassword
    }));
  }

  handleOnChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handlePasswordValidation = value => {
    const mediumRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return mediumRegex.test(value);
  };

  handleCancel = () => {
    navigate('login');
  };

  handleSubmit = async () => {
    const {
      location: {
        state: { email, password },
      },
    } = this.props;
    const { newPassword, confirmNewPassword, error } = this.state;
    let showError = false;
    if (!this.handlePasswordValidation(newPassword)) {
      showError = true;
      this.setState({
        error: {
          message:
            'The password must be at least 8 characters long and contain at least 1 number, 1 uppercase character, 1 lowercase character, and 1 symbol.',
        },
      });
    } else if (newPassword !== confirmNewPassword) {
      showError = true;
      this.setState({
        error: {
          message: 'The new and confirm password fields must match.',
        },
      });
    } else {
      showError = false;
      this.setState({
        error: null,
      });
    }

    if (!showError) {
      try {
        await authenticateUser({
          email: email.toLowerCase(),
          password,
          newPassword,
        });
        navigate('/app');
      } catch (error) {
        this.setState({
          error,
        });
      }
    }
  };

  handleKeyPress = event => {
    // check if keycode is 13 - 'enter'
    if (event.keyCode === 13) {
      // stop event from doing it's normal function
      event.preventDefault();
      // add submit function to 'enter'
      this.handleSubmit();
    }
  };

  render() {
    const { newPassword, confirmNewPassword, error } = this.state;

    return (
      <>
        <Form attached fluid segment inverted>
          {error && (
            <Message style={{ textAlign: 'left' }} negative>
              <p>{error.message}</p>
            </Message>
          )}
          <Form.Field>
            <Form.Input
              action={
                <Button icon onClick={() => this.changeNewPasswordIcon()}
                >
                  <Icon
                    name={this.state.hideNewPassword ? "eye slash" : "eye"}
                    color='#5AB7A0'
                    size={25}
                  />
                </Button>}
              actionPosition="right"
              icon="lock"
              iconPosition="left"
              label="New Password"
              name="newPassword"
              onChange={this.handleOnChange}
              placeholder="New Password"
              type={this.state.hideNewPassword ? "password" : "text"}
              value={newPassword}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              action={
                <Button icon onClick={() => this.changeConfirmPasswordIcon()}
                >
                  <Icon
                    name={this.state.hideConfirmPassword ? "eye slash" : "eye"}
                    color='#5AB7A0'
                    size={25}
                  />
                </Button>}
              actionPosition="right"
              icon="lock"
              iconPosition="left"
              label="Confirm Password"
              name="confirmNewPassword"
              onChange={this.handleOnChange}
              placeholder="Confirm Password"
              type={this.state.hideConfirmPassword ? "password" : "text"}
              value={confirmNewPassword}
            />
          </Form.Field>
          <Button floated="left" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={!newPassword || !confirmNewPassword}
            floated="left"
            onClick={this.handleSubmit}
            positive
          >
            Submit
          </Button>
        </Form>
      </>
    );
  }
}

ChangePassword.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ChangePassword;
