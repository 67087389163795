import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  Message,
  Header,
  Segment,
  Icon,
} from "semantic-ui-react";
import { graphqlMutation } from "aws-appsync-react";
import { graphql } from "react-apollo";
import { flowRight } from "lodash";
import DeleteModal from "../modal/DeleteModal";
import { UpdateCase, GetCase } from "./CaseApi";
import CaseHeader from "./CaseHeader";
import Witnesses from "../witness/Witnesses";
import DefenderCaseTable from "../defender/DefenderCaseTable";
import ProsecutorCaseTable from "../prosecutors/ProsecutorCaseTable";
import { navigate } from "../../services/RouteState";
import ReopenModal from "../modal/ReopenModal";

class CaseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
    };
  }

  handleDelete = async (id) => {
    // Delete Case
    const {
      updateCase,
      data: { getCase },
    } = this.props;
    this.setState({
      loading: true,
    });
    try {
      await updateCase({
        id,
        status: "closed",
        matterNumber: getCase.matterNumber || null,
        indictmentNumber: getCase.indictmentNumber,
      });
      navigate("/app/case/view", {
        state: { caseId: id },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  handleReopen = async (id) => {
    // Reopen Case
    const {
      updateCase,
      data: { getCase },
    } = this.props;
    this.setState({
      loading: true,
    });
    try {
      await updateCase({
        id,
        status: "active",
        matterNumber: getCase.matterNumber || null,
        indictmentNumber: getCase.indictmentNumber,
      });
      navigate("/app/case/view", {
        state: { caseId: id, loading: false },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  handleOnClick = (id) => {
    navigate("/app/case/edit", {
      state: { caseId: id },
    });
  };

  returnOnClick = () => {
    navigate("/home");
  };

  render() {
    const {
      location: { state: { caseId, pathFrom } = {} },
      data: { getCase },
    } = this.props;
    const { error, loading } = this.state;

    return (
      <>
        {/* {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )} */}
        <Grid columns="equal" relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Breadcrumb size="huge">
                <Breadcrumb.Section active>Case Information</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign="right">
              {getCase && getCase.status === "active" && (
                <>
                  {/* <Button
                  icon={{ name: 'arrow circle left' }}
                  onClick={() => this.returnOnClick()}
                  color='teal'
                  compact
                  size="medium"
                  Done
                  </Button> */}
                  <Button
                    onClick={() => this.handleOnClick(caseId)}
                    color="blue"
                    compact
                    size="medium"
                    disabled={false}
                  >
                    <Icon name="edit" />
                    Edit Case
                  </Button>
                  <DeleteModal
                    title="Closing Case"
                    name="Case Closed"
                    content={
                      <div>
                        You have indicated that the case is now complete.
                        <br />
                        <br />
                        The defense attorney will no longer have access to the
                        witnesses on the case through WitCom and will receive a
                        notification about the completed case in the WitCom
                        mobile app.
                        <br />
                        <br />
                        Do you wish to continue?
                      </div>
                    }
                    onChangePositive={() => this.handleDelete(caseId)}
                    disabled={false}
                    loading={loading}
                    color="red"
                  />
                </>
              )}
              {getCase && getCase.status === "closed" && (
                <>
                  <ReopenModal
                    title="Reopen Case"
                    type="Reopen Case"
                    name="Reopen Case"
                    content="You are about to reopen this case. Do you want to continue?"
                    onChangePositive={() => this.handleReopen(caseId)}
                    disabled={false}
                    loading={loading}
                  />
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <CaseHeader caseId={caseId} />
        <Segment>
          <Header color="teal" as="h4">
            Prosecutors
          </Header>
          <Divider hidden />
          <ProsecutorCaseTable caseId={caseId} />
        </Segment>
        <Segment>
          <Header color="teal" as="h4">
            Defense
          </Header>
          <Divider hidden />
          <DefenderCaseTable caseId={caseId} />
        </Segment>
        <Segment>
          <Witnesses caseId={caseId} />
        </Segment>
        <div style={{ paddingBottom: "50px" }} />
      </>
    );
  }
}

CaseView.propTypes = {
  location: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  updateCase: PropTypes.func.isRequired,
};

CaseView.defaultProps = {
  location: {},
  content: null,
};

export default flowRight(
  graphql(GetCase, {
    options: (props) => {
      return {
        variables: { id: props.location.state.caseId },
      };
    },
  }),
  graphqlMutation(UpdateCase)
)(CaseView);
