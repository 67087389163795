import moment from 'moment';

const PHONE_RE = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const errors = {
  email: {
    message: 'Invalid email address.',
    validators: [
      value => {
        return value.length >= 2;
      },
      value => {
        // return value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        return value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
      }
    ]
  },
  firstName: {
    message: 'Field must contain at least 2 characters.',
    validators: [
      value => {
        return value.length >= 2;
      }
    ]
  },
  lastName: {
    message: 'Field must contain at least 2 characters.',
    validators: [
      value => {
        return value.length >= 2;
      }
    ]
  },
  phoneNumberWitness: {
    message: 'Invalid phone number.',
    validators: [
      value => {
        if (value[0] !== 0) return value.match(PHONE_RE);
        else return false;
      }
    ]
  },
  phoneNumber: {
    message: 'Invalid phone number.',
    validators: [
      value => {
        const match = value.match(PHONE_RE);
        if (match === null) {
          return false;
        }
        const [_, country] = match;
        if (country != '1') {
          return false;
        }
        return true;
      }
    ]
  },
  company: {
    message: 'Field must contain at least 2 characters.',
    validators: [
      value => {
        return value.length >= 2;
      }
    ]
  },
  caseNumber: { message: 'Invalid arrest ID.', validators: [] },
  matterNumber: { message: 'Invalid docket number.', validators: [] },
  indictmentNumber: { message: 'Invalid indictment number.', validators: [] },
  arraignmentDate: {
    message: 'MM/DD/YYYY',
    validators: [
      value => {
        return moment(value).isValid();
      }
    ]
  },
  defendantFirstName: {
    message: 'Field must contain at least 2 characters.',
    validators: [
      value => {
        return value.length >= 2;
      }
    ]
  },
  defendantLastName: {
    message: 'Field must contain at least 2 characters.',
    validators: [
      value => {
        return value.length >= 2;
      }
    ]
  }
};

function validate(field, value) {
  for (let isConditionSatisfied of errors[field].validators) {
    if (!isConditionSatisfied(value)) {
      return false;
    }
  }

  return true;
}

export { errors, validate };
