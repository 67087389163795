import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { graphqlMutation } from 'aws-appsync-react';
import { navigate } from '../../services/RouteState';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Breadcrumb,
  Button,
  Dimmer,
  Divider,
  Grid,
  Form,
  Loader,
  Message,
  Segment
} from 'semantic-ui-react';

import ProsecutorGroupSelect from './ProsecutorGroupSelect';
import {
  GetProsecutor,
  CreateProsecutor,
  UpdateProsecutor
} from './ProsecutorApi';
import ConfirmModal from '../modal/ConfirmModal';
import { errors, validate } from '../models/FieldValidator.js';
import { getUserAttributes } from '../../services/Authentication';

class ProsecutorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      isDirty: false,
      loading: false,
      error: null,
      prosecutorInput: {
        group: 'prosecutor'
      }
    };
  }

  async componentDidMount() {
    const {
      data: { getProsecutor }
    } = this.props;

    const attributes = await getUserAttributes();
    this.setState({
      jurisdiction: attributes.jurisdiction
    });

    if (getProsecutor) {
      this.setState({
        edit: true,
        prosecutorInput: getProsecutor
      });
    }
  }

  componentDidUpdate() {
    const {
      data: { getProsecutor },
      location: {
        state: { prosecutorId }
      }
    } = this.props;
    const { prosecutorInput } = this.state;
    if (
      prosecutorId &&
      (getProsecutor && [...getProsecutor.id]) &&
      !prosecutorInput.id
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        prosecutorInput: getProsecutor
      });
    }
  }

  handleOnChange = event => {
    const { prosecutorInput } = this.state;

    prosecutorInput[event.target.name] = event.target.value;

    this.setState({
      prosecutorInput,
      isDirty: true
    });
  };

  handlePhoneChange = (phone, name) => {
    const { prosecutorInput } = this.state;

    prosecutorInput[name] = phone;

    this.setState({
      prosecutorInput,
      isDirty: true
    });
  };

  handleCancel = () => {
    navigate('/app/prosecutor');
  };

  handleSubmit = async () => {
    const { edit, prosecutorInput, jurisdiction } = this.state;
    const { createProsecutor, updateProsecutor } = this.props;
    this.setState({
      loading: true
    });
    let response = null;
    try {
      if (edit) {
        response = await updateProsecutor({
          id: prosecutorInput.id,
          prosecutor: {
            email: prosecutorInput.email.toLowerCase(),
            firstName: prosecutorInput.firstName,
            lastName: prosecutorInput.lastName,
            phoneNumber: prosecutorInput.phoneNumber,
            group: prosecutorInput.group,
            jurisdiction
          }
        });
      } else {
        response = await createProsecutor({
          prosecutor: {
            email: prosecutorInput.email.toLowerCase(),
            firstName: prosecutorInput.firstName,
            lastName: prosecutorInput.lastName,
            phoneNumber: prosecutorInput.phoneNumber,
            group: prosecutorInput.group,
            jurisdiction
          }
        });
      }
      if (response.data.errors) {
        this.setState({
          error: response.data.errors[0],
          loading: false
        });
        return;
      }

      const data = response.data.updateProsecutor || response.data.createProsecutor
      const params = {
        state: {
          enabled: edit ? prosecutorInput.enabled : true,
          prosecutorId: data.id
        },
      }
      navigate('/app/prosecutor/view', params)
    } catch (error) {
      this.setState({
        loading: false,
        error: response.errors[0].message
      });
    }
  };

  render() {
    const { edit, error, prosecutorInput, loading, isDirty } = this.state;

    return (
      <>
        <Grid columns='equal' relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Breadcrumb size='huge'>
                {/* <Breadcrumb.Section
                        link
                        onChangePositive
                        onClick={() => navigate('/app/prosecutor')}>
                        Prosecution
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider /> */}
                <Breadcrumb.Section active>
                  {edit ? 'Edit Prosecutor Information' : 'Register Prosecutor'}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <ConfirmModal
                type='Cancel'
                title='Unsaved Changes'
                content={
                  'You are about to exit with unsaved changes.\xa0 Do you wish to proceed without saving?'
                }
                isDirty={isDirty}
                loading={loading}
                onChangePositive={() => this.handleCancel()}
                disabled={false}
                size='small'
              />
              <ConfirmModal
                type='Submit'
                title={edit ? 'Update Prosecutor' : 'Save Prosecutor'}
                content={edit ? `You are about to update this prosecutor's information.\xa0 Do you wish to proceed?` :
                  `You are about to register a new prosecutor.\xa0 Do you wish to proceed?`}
                loading={loading}
                size='small'
                disabled={
                  !prosecutorInput.email ||
                  !validate('email', prosecutorInput.email) ||
                  !prosecutorInput.firstName ||
                  prosecutorInput.firstName.length < 2 ||
                  !prosecutorInput.lastName ||
                  prosecutorInput.lastName.length < 2 ||
                  !prosecutorInput.phoneNumber ||
                  !validate('phoneNumber', prosecutorInput.phoneNumber) ||
                  !prosecutorInput.group
                }
                isDirty={isDirty}
                onChangePositive={() => this.handleSubmit('active')}
              />
              <div style={{ paddingBottom: '25px' }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Dimmer.Dimmable active={loading}>
          <Segment>
            <Form
              autoComplete='off'
              loading={loading}
              className='attached fluid'
            >
              {error && (
                <Message negative>
                  <p>{error}</p>
                </Message>
              )}
              <Form.Field autoComplete='off'>
                <Form.Input
                  label='First Name *'
                  autoComplete='off'
                  name='firstName'
                  onChange={this.handleOnChange}
                  placeholder='First Name'
                  value={prosecutorInput.firstName}
                  error={
                    prosecutorInput.firstName ? (
                      !validate('firstName', prosecutorInput.firstName) ? (
                        <span
                          style={{
                            color: 'red',
                            fontSize: '11px'
                          }}
                        >
                          <span>{errors['firstName'].message}</span>
                        </span>
                      ) : (
                          undefined
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field>
              <Form.Field autoComplete='off'>
                <Form.Input
                  label='Last Name *'
                  autoComplete='off'
                  name='lastName'
                  onChange={this.handleOnChange}
                  placeholder='Last Name'
                  value={prosecutorInput.lastName}
                  error={
                    prosecutorInput.lastName ? (
                      !validate('lastName', prosecutorInput.lastName) ? (
                        <span
                          style={{
                            color: 'red',
                            fontSize: '11px'
                          }}
                        >
                          <span>{errors['lastName'].message}</span>
                        </span>
                      ) : (
                          undefined
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field>
              <Form.Field autoComplete='off'>
                <Form.Input
                  label='Email *'
                  autoComplete='off'
                  name='email'
                  onChange={this.handleOnChange}
                  placeholder='Email'
                  disabled={edit ? true : false}
                  value={prosecutorInput.email}
                  error={
                    prosecutorInput.email ? (
                      validate('email', prosecutorInput.email) ? (
                        undefined
                      ) : (
                          <span
                            style={{
                              color: 'red',
                              fontSize: '11px'
                            }}
                          >
                            <span>{errors['email'].message}</span>
                          </span>
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field>
              <Form.Field autoComplete='off'>
                {prosecutorInput.phoneNumber &&
                  !validate('phoneNumber', prosecutorInput.phoneNumber) ? (
                    <label style={{ color: '#9F3A38' }}>Phone Number *</label>
                  ) : (
                    <label style={{ color: 'rgba(0,0,0,0.87)' }}>Phone Number *</label>
                  )}
                <PhoneInput
                  placeholder='Phone number'
                  autoComplete='off'
                  value={prosecutorInput.phoneNumber}
                  name='phoneNumber'
                  onChange={phone =>
                    this.handlePhoneChange(phone, 'phoneNumber')
                  }
                  showCountrySelect={false}
                  country='US'
                  // eslint-disable-next-line no-nested-ternary
                  error={
                    prosecutorInput.phoneNumber ? (
                      validate('phoneNumber', prosecutorInput.phoneNumber) ? (
                        undefined
                      ) : (
                          <span
                            style={{
                              color: 'red',
                              fontSize: '11px'
                            }}
                          >
                            <span>{errors['phoneNumber'].message}</span>
                          </span>
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field>
              <Form.Field autoComplete='off'>
                <label>Account Type *</label>
                <ProsecutorGroupSelect
                  name='group'
                  onChange={this.handleOnChange}
                  value={prosecutorInput.group}
                  placeholder='Select'
                />
              </Form.Field>
            </Form>
          </Segment>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

ProsecutorForm.propTypes = {
  data: PropTypes.object,
  createProsecutor: PropTypes.func.isRequired,
  updateProsecutor: PropTypes.func.isRequired,
  location: PropTypes.object
};

ProsecutorForm.defaultProps = {
  data: {},
  location: {}
};

export default flowRight(
  graphql(GetProsecutor, {
    skip: props => {
      const {
        location: { state: { prosecutorId } = {} }
      } = props;
      return !prosecutorId;
    },
    options: props => {
      const {
        location: { state: { prosecutorId } = {} }
      } = props;
      return {
        variables: {
          id: prosecutorId
        }
      };
    }
  }),
  graphqlMutation(CreateProsecutor),
  graphqlMutation(UpdateProsecutor)
)(ProsecutorForm);
