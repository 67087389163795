import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { buildSubscription } from 'aws-appsync';
import { graphqlMutation } from 'aws-appsync-react';
import { navigate } from '../../services/RouteState';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';
import { Button, Dimmer, Loader, Message } from 'semantic-ui-react';

import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import 'react-table/react-table.css';
import '../../styles/ReactTableCustom.css';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';

import {
  ListProsecutors,
  DisableProsecutor,
  OnProsecutorSubscription
} from './ProsecutorApi';

class ProsecutorTable extends Component {
  componentDidMount() {
    const {
      data: { subscribeToMore }
    } = this.props;

    // subscribeToMore(
    //   buildSubscription(OnProsecutorSubscription, ListProsecutors)
    // );
  }

  handleDelete = async id => {
    const { disableProsecutor } = this.props;

    try {
      await disableProsecutor({ id });
    } catch (error) {
      // TODO: handle error case
    }
  };

  handleClick = row => {
    navigate('/app/prosecutor/view', {
      state: { prosecutorId: row.value, enabled: row.row.enabled }
    });
  };

  render() {
    const {
      data: { error, loading },
    } = this.props;
    let listProsecutors = this.props.data.listProsecutors && this.props.data.listProsecutors.items || []
    listProsecutors = listProsecutors.sort((a, b) => {
      return `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`)
    })
    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        {listProsecutors && (
          <Dimmer.Dimmable dimmed={loading}>
            <ReactTable
              data={listProsecutors}
              filterable
              defaultPageSize={10}
              showPageSizeOptions={false}
              style={{
                height: '79vh'
              }}
              noDataText='No Data'
              showPagination
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              defaultFiltered={[
                {
                  id: 'enabled',
                  filter: 'true'
                }
              ]}
              columns={[
                {
                  id: 'prosecutorName',
                  Header: 'Name',
                  accessor: d => `${d.lastName}, ${d.firstName}`,

                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['prosecutorName']
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none'
                  }
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['email']
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                    wordWrap: 'break-word'
                  }
                },
                {
                  Header: 'Phone',
                  accessor: 'phoneNumber',
                  Cell: ({ value }) => (value = formatPhoneNumber(value)),
                  filterMethod: (filter, row) =>
                    row[filter.id].includes(filter.value),
                  filterable: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                    wordWrap: 'break-word'
                  }
                },
                {
                  Header: 'Account Status',
                  accessor: 'enabled',
                  filterable: true,
                  sortable: false,
                  style: {
                    fontSize: '14px',
                    paddingLeft: '14px',
                    border: 'none'
                  },
                  Cell: ({ value, original }) => (
                    <>
                      <div>
                        {value === true
                          ? original.status === 'FORCE_CHANGE_PASSWORD'
                            ? 'Registered, Not Confirmed'
                            : 'Active'
                          : 'Disabled'}
                      </div>
                    </>
                  ),
                  // NOTE: Check README.md for the possible scenarios
                  filterMethod: (filter, row) => {
                    if (filter.value === 'not_confirmed') {
                      console.log('Filter method', filter, row);
                      return (
                        row[filter.id] === true &&
                        row._original.status === 'FORCE_CHANGE_PASSWORD'
                      );
                    }
                    if (filter.value === 'true') {
                      return (
                        row[filter.id] === true &&
                        row._original.status !== 'FORCE_CHANGE_PASSWORD'
                      );
                    }
                    if (filter.value === 'false') {
                      return row[filter.id] === false;
                    }
                    return true;
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : 'all'}
                      style={{
                        height: '28px',
                        width: '100%'
                      }}
                    >
                      <option value='all'>All</option>
                      <option value='true'>Active</option>
                      <option value='false'>Disabled</option>
                      <option value='not_confirmed'>
                        Registered, Not Confirmed
                      </option>
                    </select>
                  )
                },
                {
                  Header: '',
                  accessor: 'id',
                  filterable: true,
                  sortable: false,
                  style: {
                    textAlign: 'center'
                  },
                  Cell: row => (
                    <Button
                      onClick={() => this.handleClick(row)}
                      color='teal'
                      size='small'
                      compact
                    >
                      View Profile
                    </Button>
                  ),
                  filterMethod: (filter, row) => {
                    return true;
                  },
                  Filter: ({ filter, onChange }) => (
                    <span
                      style={{
                        color: 'rgba(0, 0, 0, 0.38)'
                      }}
                    >
                      <i aria-hidden='true' class='search icon' /> Column
                      Filters
                    </span>
                  )
                }
              ]}
            />
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        )}
      </>
    );
  }
}

ProsecutorTable.propTypes = {
  data: PropTypes.object,
  disableProsecutor: PropTypes.func.isRequired
};

ProsecutorTable.defaultProps = {
  data: {}
};

export default flowRight(
  graphql(ListProsecutors, {
    options: props => {
      return {
        variables: {
          jurisdiction: props.jurisdiction
        }
      };
    }
  }),
  graphqlMutation(DisableProsecutor, ListProsecutors)
)(ProsecutorTable);
