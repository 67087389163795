import { navigate as _navigate } from 'gatsby';

const PATH_KEY = 'route.path';
const ARGS_KEY = 'route.args';

export function navigate(path, args = {}) {
  console.debug('navigate', path, args);
  const data = JSON.stringify(args);
  localStorage.setItem(PATH_KEY, path);
  localStorage.setItem(ARGS_KEY, data);
  _navigate(path, args);
}

export default {
  loadState: function () {
    const path = localStorage.getItem(PATH_KEY);
    if (!path) {
      return;
    }

    const data = localStorage.getItem(ARGS_KEY);
    if (data !== 'undefined') {
      const args = JSON.parse(data);
      console.log('path', path, 'args', args);
      _navigate(path, args);
    } else {
      _navigate(path);
    }
  },
};
