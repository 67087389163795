import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";

class DeleteModal extends Component {
  state = {
    open: false,
  };

  show = (size) => () => this.setState({ size, open: true });

  close = () => this.setState({ open: false });

  handlePositive = () => {
    const { onChangePositive } = this.props;
    onChangePositive();
    this.setState({
      open: false,
    });
  };

  handleNegative = () => {
    const { onChangeNegative } = this.props;
    onChangeNegative();
    this.setState({
      open: false,
    });
  };

  render() {
    const { open, size } = this.state;
    const {
      title,
      // icon,
      content,
      onChangeNegative,
      name,
      loading,
      color,
      basic,
      disabled,
    } = this.props;

    return (
      <>
        <Button
          onClick={this.show("tiny")}
          loading={loading}
          // icon={icon || null}
          negative
          size="small"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          color={color || null}
          basic={basic || false}
          disabled={disabled}
        >
          {name === "Case Closed" ? <Icon name="gavel" /> : null}
          {name}
        </Button>

        <Modal size={size} open={open} onClose={this.close}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>{content}</Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={onChangeNegative ? this.handleNegative : this.close}
            >
              No
            </Button>
            <Button positive onClick={this.handlePositive}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChangePositive: PropTypes.func.isRequired,
  name: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  basic: PropTypes.bool,
  onChangeNegative: PropTypes.func,
};

DeleteModal.defaultProps = {
  onChangeNegative: null,
  loading: false,
  name: null,
  color: null,
  basic: false,
};

export default DeleteModal;
