import React from 'react';

import { Divider, Header } from 'semantic-ui-react';

import ProfileDetail from './ProfileDetail';

const Profile = () => (
  <>
    <Header as="h1">Profile</Header>
    <Divider />
    <ProfileDetail />
  </>
);

export default Profile;
