import React, { Component } from 'react';

import { Link } from 'gatsby';
import { navigate } from '../../services/RouteState';
import { Button, Form, Message, Container, Grid, Icon, Input } from 'semantic-ui-react';

import {
  authenticateUser,
  isAuthenticated,
} from '../../services/Authentication';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      icon: 'eye slash',
      hidePassword: true
    };
    // bind function to component
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  async componentDidMount() {
    // when component mounts, add a document listener
    document.addEventListener('keydown', this.handleKeyPress, false);

    try {
      // check if user is authenticated and sign them in if they are
      await isAuthenticated();
      navigate('/app');
    } catch (error) {
      // nothing
    }
  }

  componentWillUnmount() {
    // when component unmounts, delete document listener so there aren't any accidental fires in other components
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  changeIcon() {
    this.setState(prevState => ({
      icon: prevState.icon === 'eye slash' ? 'eye' : 'eye slash',
      hidePassword: !prevState.hidePassword
    }));
  }

  handleOnChange = event =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleSubmit = async () => {
    const { email, password } = this.state;

    try {
      await authenticateUser({
        email: email.toLowerCase(),
        password,
      });

      navigate('/app');
    } catch (error) {
      if (error.code === 'NewPasswordRequired') {
        navigate('/change', {
          state: {
            email: email.toLowerCase(),
            password,
          },
        });
      } else {
        this.setState({
          error,
        });
      }
    }
  };

  handleKeyPress = event => {
    // check if keycode is 13 - 'enter'
    if (event.keyCode === 13) {
      // stop event from doing it's normal function
      event.preventDefault();
      // add submit function to 'enter'
      this.handleSubmit();
    }
  };

  render() {
    const { email, password, error } = this.state;

    return (
      <>
        <Form attached="true" fluid="true" segment="true" inverted>
          {error && (
            <Message negative>
              <p>{'Incorrect username or password.'}</p>
            </Message>
          )}
          <Form.Field>
            <Form.Input
              icon="mail"
              iconPosition="left"
              name="email"
              onChange={this.handleOnChange}
              placeholder="Email"
              value={email}
            />
          </Form.Field>
          <Form.Field>
            <Input
              actionPosition="right"
              action={
                <Button icon onClick={() => this.changeIcon()}
                >
                  <Icon

                    name={this.state.hidePassword ? "eye slash" : "eye"}
                    color='#5AB7A0'
                    size={25}
                  />
                </Button>}
              icon="lock"
              iconPosition="left"
              name="password"
              onChange={this.handleOnChange}
              placeholder="Password"
              type={this.state.hidePassword ? "password" : "text"}
              value={password}
            />
          </Form.Field>
          <Container>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column textAlign="left">
                  <Button
                    disabled={!email || !password}
                    floated="left"
                    onClick={this.handleSubmit}
                    positive
                  >
                    Login
                  </Button>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Link to={`/forgot`}
                    state={{ email: email.toLowerCase() }}>Forgot password?</Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Form>
      </>
    );
  }
}

export default Login;
