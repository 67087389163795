import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '../../services/RouteState';
import moment from 'moment';
import { withApollo } from 'react-apollo';
import {
  Breadcrumb,
  Button,
  Dimmer,
  Grid,
  Loader,
  Message,
  Segment,
  Link
} from 'semantic-ui-react';
import ReactTable from 'react-table';
import {
  getUserGroup,
  isAuthenticated,
  getUserAttributes
} from '../../services/Authentication';
import { ListProsecutorCases, ListCase } from '../case/CaseApi';
import matchSorter from 'match-sorter';
import UserContext from '../context/UserContext';

const TABLE_STYLE = { height: '78vh' };
const COLUMN_STYLE = { fontSize: '14px', border: 'none' };

class IdentityTable extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      cases: [],
      loading: true,
      error: null
    };
  }

  async componentDidMount() {
    const {
      client: { query }
    } = this.props;
    try {
      await isAuthenticated();
      const group = await getUserGroup();
      const attributes = await getUserAttributes();
      const jurisdiction = this.context.user.jurisdiction;
      const variables = {
        filter: {
          id: attributes.sub,
          jurisdiction: jurisdiction
        }
      };
      const cases = await query({
        query: ListCase,
        variables: variables
      });
      this.setState({
        group: group,
        error: cases.error,
        loading: cases.loading,
        cases: cases.data.listCase,
        jurisdiction: jurisdiction
      });
    } catch (error) {
      this.setState({
        error
      });
    }
  }

  handleClick = id => {
    navigate('/app/case/view', {
      state: { caseId: id, pathFrom: 'my_cases' }
    });
  };

  render() {
    const { cases, jurisdiction, loading, error, group } = this.state;

    return (
      <>
        <Grid columns='equal' relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Breadcrumb size='huge'>
                <Breadcrumb.Section active>My Cases</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        {cases && !loading && (
          <Dimmer.Dimmable dimmed={loading}>
            <ReactTable
              filterable
              data={cases.items}
              style={TABLE_STYLE}
              defaultPageSize={10}
              showPageSizeOptions={false}
              noDataText='No Case Data Available'
              defaultSorted={[
                {
                  id: "caseNumber",
                  desc: false
                }
              ]}
              defaultFiltered={[
                {
                  id: 'status',
                  value: 'all'
                },
                {
                  id: 'assigned',
                  value: 'assigned'
                }
              ]}
              defaultFilterMethod={(filter, row) => {
                const key = filter.id;
                const value = filter.value;
                return row[key] === value;
              }}
              columns={[
                {
                  filterAll: true,
                  filterable: true,
                  style: COLUMN_STYLE,
                  id: 'caseNumber',
                  Header: 'Arrest ID',
                  accessor: row => row.caseNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, { keys: ['caseNumber'] })
                },
                {
                  filterAll: true,
                  filterable: true,
                  style: COLUMN_STYLE,
                  id: 'matterNumber',
                  Header: 'Docket No.',
                  accessor: row => row.matterNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, { keys: ['matterNumber'] }),
                  Cell: ({ original }) => {
                    return <span>{original.matterNumber || '-'}</span>;
                  }
                },
                {
                  filterAll: true,
                  style: COLUMN_STYLE,
                  id: 'indictmentNumber',
                  Header: 'Indictment No.',
                  accessor: row => row.indictmentNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, {
                      keys: ['indictmentNumber']
                    }),
                  Cell: ({ original }) => {
                    return <span>{original.indictmentNumber || '-'}</span>;
                  }
                },
                {
                  filterAll: true,
                  id: 'defendantName',
                  Header: 'Defendant',
                  style: COLUMN_STYLE,
                  accessor: row =>
                    `${row.defendantLastName}, ${row.defendantFirstName}`,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['defendantName']
                    })
                },
                {
                  id: 'status',
                  sortable: false,
                  filterAll: false,
                  filterable: true,
                  style: COLUMN_STYLE,
                  Header: 'Case Status',
                  accessor: row => row.status,
                  Cell: ({ original }) => {
                    if (original.status === 'active') {
                      return <span>Open</span>;
                    }
                    return <span>Closed</span>;
                  },
                  filterMethod: (filter, row) => {
                    switch (filter.value) {
                      case 'true':
                        return row.status === 'active';
                      case 'false':
                        return row.status === 'closed';
                      default:
                        // case all
                        return true;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter.value}
                      style={{ height: '28px', width: '100%' }}
                    >
                      <option value='true'>Open</option>
                      <option value='false'>Closed</option>
                      <option value='all'>All</option>
                    </select>
                  )
                },
                {
                  id: 'assigned',
                  sortable: false,
                  filterAll: false,
                  filterable: true,
                  style: COLUMN_STYLE,
                  Header: 'Assignment Status',
                  accessor: row => row.assigned,
                  Cell: ({ original }) => {
                    if (original.assigned === 'true') {
                      return <span>Assigned to Case</span>;
                    }
                    return <span>Removed from Case</span>;
                  },
                  filterMethod: (filter, row) => {
                    switch (filter.value) {
                      case 'assigned':
                        return row.assigned === 'true';
                      case 'removed':
                        return row.assigned === 'false';
                      default:
                        // case all
                        return true;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      value={filter.value}
                      style={{ height: '28px', width: '100%' }}
                      onChange={event => onChange(event.target.value)}
                    >
                      <option value='assigned'>Assigned to Case</option>
                      <option value='removed'>Removed from Case</option>
                      <option value='all'>All</option>
                    </select>
                  )
                },
                {
                  id: 'home',
                  Header: '',
                  accessor: row => row.id,
                  sortable: false,
                  filterable: true,
                  style: { textAlign: 'center' },
                  Cell: ({ value }) => (
                    <div>
                      <Button
                        compact
                        size='small'
                        color='teal'
                        onClick={() => this.handleClick(value)}
                      >
                        Case Home
                        </Button>
                    </div>
                  ),
                  Filter: ({ filter, onChange }) => (
                    <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                      <i aria-hidden='true' class='search icon' />
                      Column Filters
                    </span>
                  )
                }
              ]}
            />
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        )}
      </>
    );
  }
}

IdentityTable.propTypes = {
  client: PropTypes.object,
  deleteCase: PropTypes.func.isRequired
};

IdentityTable.defaultProps = {
  client: {}
};

export default withApollo(IdentityTable);
