import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { buildSubscription } from 'aws-appsync';
import { graphqlMutation } from 'aws-appsync-react';
import { navigate } from '../../services/RouteState';
import moment from 'moment';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';
import { Button, Dimmer, Loader, Message, Segment } from 'semantic-ui-react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import 'react-table/react-table.css';

import { ListCase, DeleteCase, OnCaseSubscription } from './CaseApi';
import UserContext from '../context/UserContext';
import { getUserGroup } from '../../services/Authentication';

class CaseTable extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    const group = await getUserGroup();
    this.setState({
      jurisdiction: this.context.user.jurisdiction,
      group: group,
    });
  }

  handleDelete = async id => {
    const { deleteCase } = this.props;

    try {
      await deleteCase({ id });
    } catch (error) {
      // TODO: handle error case
    }
  };

  handleClick = id => {
    navigate('/app/case/view', {
      state: { caseId: id, pathFrom: 'find_case' },
    });
  };

  render() {
    const {
      data: { error, loading, listCase, fetchMore },
    } = this.props;
    const { jurisdiction, group } = this.state;
    const showButton = jurisdiction !== 'newyork-ny' || group !== 'prosecutor';

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        {listCase && (
          <Dimmer.Dimmable dimmed={loading}>
            <ReactTable
              data={listCase.items}
              filterable
              defaultPageSize={10}
              style={{
                height: '78vh',
              }}
              showPagination={true}
              className="-highlight"
              noDataText="No Case Data Available"
              defaultSorted={[
                {
                  id: "caseNumber",
                  desc: false
                }
              ]}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              showPageSizeOptions={false}
              defaultFiltered={[
                {
                  id: 'status',
                  filter: 'true',
                },
              ]}
              columns={[
                {
                  Header: 'Arrest ID',
                  accessor: 'caseNumber', // String-based value accessors!
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['caseNumber'],
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                  },
                },
                {
                  Header: 'Docket No.',
                  accessor: 'matterNumber', // String-based value accessors!
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['matterNumber'],
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                  },
                  Cell: row => <span>{row.value || '-'}</span>,
                },
                {
                  Header: 'Indictment No.',
                  accessor: 'indictmentNumber', // String-based value accessors!
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['indictmentNumber'],
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                  },
                  Cell: row => <span>{row.value || '-'}</span>,
                },
                {
                  id: 'defendantName',
                  Header: 'Defendant',
                  accessor: d =>
                    `${d.defendantLastName}, ${d.defendantFirstName}`,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['defendantName'],
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                  },
                },
                {
                  Header: 'WitCom Start',
                  accessor: 'createdAt',
                  style: {
                    fontSize: '14px',
                    border: 'none',
                  },
                  filterMethod: (filter, row) =>
                    moment(row[filter.id])
                      .format('MM/DD/YYYY')
                      .includes(filter.value) ||
                    moment(row[filter.id])
                      .format('MM/DD/YYYY')
                      .toLowerCase()
                      .includes(filter.value),
                  filterable: true,
                  Cell: row => moment(row.value).format('MM/DD/YYYY'),
                },
                {
                  Header: 'Case Status',
                  accessor: 'status',
                  filterable: true,
                  sortable: false,
                  filterAll: false,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                  },
                  Cell: row =>
                    row.value === 'active' ? (
                      <span>Open</span>
                    ) : (
                        <span>Closed</span>
                      ),
                  filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                      return true;
                    }
                    if (filter.value === 'false') {
                      return row[filter.id] === 'closed';
                    }
                    return row[filter.id] === 'active';
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : 'all'}
                      style={{
                        height: '28px',
                        width: '100%',
                      }}
                    >
                      <option value="true">Open</option>
                      <option value="false">Closed</option>
                      <option value="all">All</option>
                    </select>
                  ),
                },
                {
                  Header: '',
                  accessor: 'id',
                  filterable: true,
                  sortable: false,
                  style: {
                    textAlign: 'center',
                  },
                  Cell: row => (
                    <div>
                      {showButton ? (
                        <Button
                          onClick={() => this.handleClick(row.value)}
                          color="teal"
                          size="small"
                          compact
                        >
                          Case Home
                    </Button>
                      ) : null}
                    </div>
                  ),
                  filterMethod: (filter, row) => {
                    return true;
                  },
                  Filter: ({ filter, onChange }) => (
                    <span
                      style={{
                        color: 'rgba(0, 0, 0, 0.38)',
                      }}
                    >
                      <i aria-hidden="true" className="search icon" /> Column
                      Filters
                    </span>
                  ),
                },
              ]}
            />
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        )}
      </>
    );
  }
}

CaseTable.propTypes = {
  data: PropTypes.object,
  deleteCase: PropTypes.func.isRequired,
};

CaseTable.defaultProps = {
  data: {},
};

export default flowRight(
  graphql(ListCase, {
    options: props => {
      return {
        variables: {
          filter: {
            jurisdiction: props.jurisdiction,
          },
        },
      };
    },
  }),
  graphqlMutation(DeleteCase)
)(CaseTable);
