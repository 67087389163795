import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Header } from 'semantic-ui-react';

import WitnessTable from './WitnessTable';

const Witnesses = ({ caseId }) => (
    <>
        <Header color='teal' as='h4'>
            Witnesses
        </Header>
        <Divider hidden />
        <WitnessTable caseId={caseId} />
    </>
);

Witnesses.propTypes = {
    caseId: PropTypes.string.isRequired
};

export default Witnesses;
