import React, { Component } from "react";
import PropTypes from "prop-types";

import { buildSubscription } from "aws-appsync";
import moment from "moment";
import { graphql } from "react-apollo";
import { flowRight } from "lodash";
import {
  Dimmer,
  Divider,
  Icon,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";

import { GetCase, OnCaseSubscription } from "./CaseApi";

class CaseHeader extends Component {
  componentDidMount() {
    const {
      data: { subscribeToMore },
    } = this.props;

    subscribeToMore(buildSubscription(OnCaseSubscription, GetCase));
  }

  upperCase = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const {
      data: { error, loading, getCase },
    } = this.props;

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        {getCase && (
          <div style={{ backgroundColor: "#fff" }}>
            <Segment>
              <Dimmer.Dimmable dimmed={loading}>
                <Grid columns="equal" relaxed stretched stackable divided>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <Header as="h4">
                        <Header.Content>
                          {getCase.caseNumber}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name="clipboard" /> Arrest ID
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">
                        <Header.Content>
                          {getCase.matterNumber || "-"}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name="file" /> Docket No.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">
                        <Header.Content>
                          {getCase.indictmentNumber || "-"}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name="university" /> Indictment No.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">
                        <Header.Content>
                          {`${getCase.defendantLastName}, ${getCase.defendantFirstName}`}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name="user" />
                            Defendant
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">
                        <Header.Content>
                          {moment(getCase.arraignmentDate).format("MM/DD/YYYY")}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name="times circle" /> Arraignment
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">
                        <Header.Content>
                          {moment(getCase.createdAt).format("MM/DD/YYYY")}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name="add to calendar" /> WitCom Start
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">
                        <Header.Content>
                          {getCase.status === "active" ? "Open" : "Closed"}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name="folder" /> Case Status
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Dimmer active={loading} inverted>
                  <Loader />
                </Dimmer>
              </Dimmer.Dimmable>
            </Segment>
          </div>
        )}
      </>
    );
  }
}

CaseHeader.propTypes = {
  data: PropTypes.object,
};

CaseHeader.defaultProps = {
  data: {},
};

export default flowRight(
  graphql(GetCase, {
    skip: (props) => !props.caseId,
    options: (props) => ({
      variables: {
        id: props.caseId,
      },
    }),
  })
)(CaseHeader);
