import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Grid } from 'semantic-ui-react';
import CaseTable from './CaseTable';
import { getUserAttributes } from '../../services/Authentication';

class Cases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: null
    };
  }

  async componentDidMount() {
    const attributes = await getUserAttributes();
    this.setState({ jurisdiction: attributes.jurisdiction });
  }

  render() {
    const {
      location: {
        state: { type }
      }
    } = this.props;

    const { jurisdiction } = this.state;
    return (
      <>
        <Grid columns='equal' relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Breadcrumb size='huge'>
                <Breadcrumb.Section active>Find Case</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign='right' />
          </Grid.Row>
        </Grid>
        <CaseTable jurisdiction={jurisdiction} type={type || 'active'} />
      </>
    );
  }
}

Cases.propTypes = {
  location: PropTypes.object.isRequired
};

export default Cases;
