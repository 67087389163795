import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { navigate } from '../../services/RouteState';

import { graphql, withApollo } from 'react-apollo';
import { flowRight } from 'lodash';
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Message,
  Table,
} from 'semantic-ui-react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import {
  ListCase,
  ListProsecutorCases,
  ListDefenderCases,
  ListCaseDefenders,
} from '../case/CaseApi';
import { AST_True } from 'terser';
import { isUndefined } from 'util';
import { isNullOrUndefined } from 'util';
import { getUserAttributes, getUserGroup } from '../../services/Authentication';
import UserContext from '../context/UserContext';

const TABLE_STYLE = { height: '78vh' };
const COLUMN_STYLE = { fontSize: '14px', border: 'none' };

class IdentityTable extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {
      defenderId,
      prosecutorId,
      client: { query },
    } = this.props;
    const group = await getUserGroup();
    const identityId = prosecutorId || defenderId;
    const jurisdiction = this.context.user.jurisdiction;
    const variables = {
      filter: {
        id: identityId,
        jurisdiction: jurisdiction,
      },
    };
    const cases = await query({
      query: ListCase,
      variables: variables,
    });

    this.setState({
      group: group,
      jurisdiction: jurisdiction,
      cases: cases.data.listCase,
    });
  }

  handleDelete = async (id) => {
    const { deleteCase } = this.props;
    try {
      await deleteCase({ id });
    } catch (err) {
      console.error(err);
    }
  };

  handleClick = (id) => {
    navigate('/app/case/view', {
      state: { caseId: id, pathFrom: 'find_case' },
    });
  };

  render() {
    const { error, loading } = this.props.data;
    const { jurisdiction, group, cases } = this.state;
    const filters = [];
    const showButton = jurisdiction !== 'newyork-ny' || group !== 'prosecutor';

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        <Header>Cases</Header>
        {cases && (
          <Dimmer.Dimmable dimmed={loading}>
            <ReactTable
              filterable
              data={cases.items}
              style={TABLE_STYLE}
              defaultPageSize={10}
              className="-highlight"
              showPageSizeOptions={false}
              noDataText="No Case Data Available"
              defaultSorted={[
                {
                  id: 'caseNumber',
                  desc: false,
                },
              ]}
              defaultFiltered={[
                {
                  id: 'status',
                  value: 'all',
                },
                {
                  id: 'assigned',
                  value: 'assigned',
                },
              ]}
              defaultFilterMethod={(filter, row, column) => {
                const key = filter.id;
                const value = filter.value;
                return row[key] === value;
              }}
              columns={[
                {
                  filterAll: true,
                  filterable: true,
                  style: COLUMN_STYLE,
                  id: 'caseNumber',
                  Header: 'Arrest ID',
                  accessor: (row) => row.caseNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, { keys: ['caseNumber'] }),
                },
                {
                  filterAll: true,
                  filterable: true,
                  style: COLUMN_STYLE,
                  id: 'matterNumber',
                  Header: 'Docket No.',
                  accessor: (row) => row.matterNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, { keys: ['matterNumber'] }),
                  Cell: ({ original }) => {
                    return <span>{original.matterNumber || '-'}</span>;
                  },
                },
                {
                  filterAll: true,
                  style: COLUMN_STYLE,
                  id: 'indictmentNumber',
                  Header: 'Indictment No.',
                  accessor: (row) => row.indictmentNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, {
                      keys: ['indictmentNumber'],
                    }),
                  Cell: ({ original }) => {
                    return <span>{original.indictmentNumber || '-'}</span>;
                  },
                },
                {
                  filterAll: true,
                  id: 'defendantName',
                  Header: 'Defendant',
                  style: COLUMN_STYLE,
                  accessor: (row) =>
                    `${row.defendantLastName}, ${row.defendantFirstName}`,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['defendantName'],
                    }),
                },
                {
                  id: 'status',
                  sortable: false,
                  filterAll: false,
                  filterable: true,
                  style: COLUMN_STYLE,
                  Header: 'Case Status',
                  accessor: (row) => row.status,
                  Cell: ({ original }) => {
                    if (original.status === 'active') {
                      return <span>Open</span>;
                    }
                    return <span>Closed</span>;
                  },
                  filterMethod: (filter, row) => {
                    switch (filter.value) {
                      case 'true':
                        return row.status === 'active';
                      case 'false':
                        return row.status === 'closed';
                      default:
                        // case all
                        return true;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      value={filter.value}
                      style={{ height: '28px', width: '100%' }}
                      onChange={(event) => onChange(event.target.value)}
                    >
                      <option value="true">Open</option>
                      <option value="false">Closed</option>
                      <option value="all">All</option>
                    </select>
                  ),
                },
                {
                  id: 'assigned',
                  sortable: false,
                  filterAll: false,
                  filterable: true,
                  style: COLUMN_STYLE,
                  Header: 'Assignment Status',
                  accessor: (row) => row.assigned,
                  Cell: ({ original }) => {
                    if (original.assigned === 'true') {
                      return <span>Assigned to Case</span>;
                    }
                    return <span>Removed from Case</span>;
                  },
                  filterMethod: (filter, row) => {
                    switch (filter.value) {
                      case 'assigned':
                        return row.assigned === 'true';
                      case 'removed':
                        return row.assigned === 'false';
                      default:
                        // case all
                        return true;
                    }
                  },
                  Filter: ({ filter, onChange }) => {
                    return (
                      <select
                        value={filter.value}
                        style={{ height: '28px', width: '100%' }}
                        onChange={(event) => onChange(event.target.value)}
                      >
                        <option value="assigned">Assigned to Case</option>
                        <option value="removed">Removed from Case</option>
                        <option value="all">All</option>
                      </select>
                    );
                  },
                },
                {
                  id: 'home',
                  Header: '',
                  accessor: (row) => row.id,
                  sortable: false,
                  filterable: true,
                  style: { textAlign: 'center' },
                  Cell: ({ value }) => (
                    <div>
                      {showButton ? (
                        <Button
                          compact
                          size="small"
                          color="teal"
                          onClick={() => this.handleClick(value)}
                        >
                          Case Home
                        </Button>
                      ) : null}
                    </div>
                  ),
                  Filter: ({ filter, onChange }) => (
                    <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                      <i aria-hidden="true" className="search icon" />
                      Column Filters
                    </span>
                  ),
                },
              ]}
            />
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        )}
      </>
    );
  }
}

IdentityTable.propTypes = {
  data: PropTypes.object,
  deleteCase: PropTypes.func,
};

IdentityTable.defaultProps = {
  data: {},
};

export default withApollo(IdentityTable);
