import React, { Component } from 'react';
import { navigate } from '../../services/RouteState';
import { Breadcrumb, Button, Grid, Segment } from 'semantic-ui-react';
import DefenderTable from './DefenderTable';
import { getUserAttributes } from '../../services/Authentication';

class Defenders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ''
    };
  }

  async componentWillMount() {
    const attributes = await getUserAttributes();
    this.setState({ jurisdiction: attributes.jurisdiction });
  }

  handleOnClick = () => {
    navigate('/app/defender/edit');
  };

  render() {
    const { jurisdiction } = this.state;
    return (
      <>
        <Grid columns='equal' relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Breadcrumb size='huge'>
                <Breadcrumb.Section active>
                  Defense List
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {jurisdiction && <DefenderTable jurisdiction={jurisdiction} />}
      </>
    );
  }
}

export default Defenders;
