import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import {
  Button,
  Checkbox,
  Dimmer,
  Icon,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';

import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import 'react-table/react-table.css';
import '../../../styles/ReactTableCustom.css';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';

import { ListProsecutors } from '../../prosecutors/ProsecutorApi';
import { getUserAttributes } from '../../../services/Authentication';

class SearchProsecutorsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      size: 'fullscreen',
      loading: false,
      prosecutorArray: [],
      prosecutorResponse: [],
      prosecutorSelection: [],
      prosecutorDeselection: [],
    };
  }

  componentDidMount() {
    const { prosecutors } = this.props;
    if (prosecutors && prosecutors.items) {
      this.setState({
        prosecutorArray: prosecutors,
      });
    }
  }

  handleToggle = (event, data, row) => {
    const { onChange } = this.props;
    const { checked } = data;
    const newId = row.value;
    let { prosecutorSelection } = this.state;
    let { prosecutorDeselection } = this.state;
    let { prosecutorArray } = this.state;
    if (checked === true) {
      // prosecutorArray = prosecutorArray.filter(item => item.id !== newId);
      prosecutorSelection.push(row.value);
      prosecutorArray.push({
        id: row.value,
        firstName: row.row.prosecutorName.split(', ')[1],
        lastName: row.row.prosecutorName.split(', ')[0],
        email: row.row.email,
        phoneNumber: row.row.phoneNumber,
      });
      this.setState({
        prosecutorArray,
        prosecutorSelection,
      });
    }
    if (checked === false) {
      prosecutorArray = prosecutorArray.filter(item => item.id !== newId);
      prosecutorDeselection.push({
        id: row.value,
        firstName: row.row.prosecutorName.split(', ')[1],
        lastName: row.row.prosecutorName.split(', ')[0],
        email: row.row.email,
        phoneNumber: row.row.phoneNumber,
      });
      this.setState({
        prosecutorArray,
        prosecutorDeselection,
      });
    }
    onChange('prosecutorsInput', prosecutorArray);
  };

  handleGetData = async () => {
    const {
      client: { query },
    } = this.props;
    this.setState({
      loading: true,
    });
    const attributes = await getUserAttributes();

    const response = await query({
      query: ListProsecutors,
      name: 'listProsecutors',
      variables: {
        group: '',
        jurisdiction: attributes.jurisdiction,
      },
    });

    this.setState({
      prosecutorResponse: response.data.listProsecutors.items,
      loading: false,
    });
  };

  show = size => () => {
    const { prosecutors } = this.props;
    this.handleGetData();
    this.setState({
      prosecutorArray: prosecutors,
      size,
      open: true,
      prosecutorSelection: [],
      prosecutorDeselection: [],
    });
  };

  close = () => {
    const { prosecutors } = this.props;
    const { onChange } = this.props;
    const holderSelection = prosecutors.filter(
      item => this.state.prosecutorSelection.indexOf(item.id) === -1
    );
    const holderDeselection = this.state.prosecutorDeselection;
    const holder = holderSelection.concat(holderDeselection);
    this.setState({
      prosecutorArray: holder,
      open: false,
      prosecutorSelection: [],
      prosecutorDeselection: [],
    });
    onChange('prosecutorsInput', holder);
  };

  addProsecutor = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { open, size, prosecutorResponse, loading } = this.state;
    const {
      prosecutors,
      data: { error },
    } = this.props;
    let sortedProsecutors = prosecutorResponse || []
    sortedProsecutors = sortedProsecutors.sort((a, b) => {
      return `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`)
    })
    return (
      <>
        <Button
          compact
          onClick={this.show('large')}
          basic
          color="teal"
          size="small"
          style={{ marginTop: '15px' }}
        >
          <Icon name="plus" /> Add Prosecutors
        </Button>
        <Modal
          size={size}
          centered={false}
          open={open}
          onClose={this.close}
          closeIcon
        >
          <Modal.Header>Prosecutors</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ paddingBottom: '20px' }}>
              From the list, select the prosecutors who are assigned to the
              case.
            </Modal.Description>
            {error || !sortedProsecutors ? (
              <Message negative>
                <p>
                  There was an error while getting the list of available
                  prosecutors. Please close this popup and try again.
                </p>
              </Message>
            ) : (
                <Dimmer.Dimmable active={loading}>
                  <ReactTable
                    data={sortedProsecutors}
                    filterable
                    loading={loading}
                    defaultPageSize={10}
                    showPageSizeOptions={false}
                    style={{
                      height: '65vh',
                    }}
                    noDataText="No Prosecutor Data Available"
                    showPagination
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]) === filter.value
                    }
                    columns={[
                      {
                        id: 'prosecutorName',
                        Header: 'Prosecutor Name',
                        accessor: d => `${d.lastName}, ${d.firstName}`,

                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['prosecutorName'],
                          }),
                        filterAll: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                      },
                      {
                        Header: 'Email Address',
                        accessor: 'email',
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ['email'] }),
                        filterAll: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                      },
                      {
                        Header: 'Phone Number',
                        accessor: 'phoneNumber',
                        Cell: ({ value }) => (value = formatPhoneNumber(value)),
                        filterMethod: (filter, row) =>
                          row[filter.id].startsWith(filter.value) ||
                          row[filter.id].substring(2).startsWith(filter.value),
                        filterable: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                      },
                      {
                        Header: 'Add to Case',
                        accessor: 'id',
                        filterable: false,
                        sortable: false,
                        style: {
                          textAlign: 'center',
                        },
                        id: 'checkbox',
                        Cell: row => {
                          return (
                            <Checkbox
                              id={row.id}
                              type="checkbox"
                              checked={
                                prosecutors.find(data => data.id === row.value) ||
                                false
                              }
                              onClick={(event, data) =>
                                this.handleToggle(event, data, row)
                              }
                            />
                          );
                        },
                      },
                    ]}
                  />
                  <Dimmer active={loading} inverted>
                    <Loader />
                  </Dimmer>
                </Dimmer.Dimmable>
              )}
          </Modal.Content>
          <Modal.Actions>
            {/* <Button onClick={() => this.logIt()}>Log it</Button> */}
            <Button negative size="small" onClick={this.close}>
              Cancel
            </Button>
            <Button positive size="small" onClick={this.addProsecutor}>
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
SearchProsecutorsModal.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  prosecutors: PropTypes.array,
  client: PropTypes.array.isRequired,
};

SearchProsecutorsModal.defaultProps = {
  prosecutors: [],
  data: [],
};

export default withApollo(SearchProsecutorsModal);
