import React, { Component } from "react";
import PropTypes from "prop-types";

import { navigate } from "../../services/RouteState";
import { graphql } from "react-apollo";
import { flowRight } from "lodash";

import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";

import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Loader,
  Header,
  Icon,
  Table,
} from "semantic-ui-react";
import { ListWitnesses, ListPhoneTypes } from "../witness/WitnessApi";
import LanguageDropdown from "../language/LanguageDropdown";
import { errors, validate } from "../models/FieldValidator.js";

class CaseFormWitness extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      witnessesInput: [],
    };
  }

  componentDidMount() {
    const {
      data: { listWitnesses },
      onChange,
    } = this.props;
    if (listWitnesses && [...listWitnesses.items]) {
      const holder = listWitnesses.items.map((item) => ({
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        trialWitness: item.trialWitness,
        language: item.language,
        phoneNumbers: [
          {
            type: item.phoneNumbers[0].type,
            number: item.phoneNumbers[0].number,
          },
        ],
        preferredContactMethod: item.preferredContactMethod,
      }));
      // this.state.witnesses = listWitnesses.items;
      this.setStateOnLoad(holder);
    } else {
      let cachedState = sessionStorage.getItem("create_case_state");
      if (cachedState) {
        cachedState = JSON.parse(cachedState);
        if (cachedState.witnessesInput.length > 0) {
          let number = cachedState.witnessesInput[0].phoneNumbers[0].number;
          if (!number.startsWith("+")) {
            number = "+1" + number.replace("/ -()/g", "");
          }
          cachedState.witnessesInput[0].phoneNumbers[0].number = number;
        }
        console.log("cachedState", cachedState);
        this.setStateOnLoad(cachedState.witnessesInput);
      }
    }
  }

  setStateOnLoad(witnessesInput) {
    const { onChange } = this.props;
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      isDirty: true,
      witnessesInput,
    });
    onChange("witnessesInput", witnessesInput);
  }

  componentDidUpdate(prevProps) {
    const {
      path,
      data: { listWitnesses },
      onChange,
      caseId,
    } = this.props;
    const { witnessesInput, isDirty } = this.state;
    // load up correct data on edit
    if (
      caseId &&
      listWitnesses &&
      [...listWitnesses.items].length > 0 &&
      witnessesInput.length === 0 &&
      !isDirty
    ) {
      const holder = listWitnesses.items.map((item) => ({
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        trialWitness: item.trialWitness,
        language: item.language,
        phoneNumbers: [
          {
            type: item.phoneNumbers[0].type,
            number: item.phoneNumbers[0].number,
          },
        ],
        preferredContactMethod: item.preferredContactMethod,
      }));
      // this.state.witnesses = listWitnesses.items;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        witnessesInput: holder,
      });
      onChange("witnessesInput", holder);
    }

    // this should only be fired when a user is currently in a case edit screen and clicks the enter case nav menu item
    if (prevProps.caseId && !caseId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isDirty: false,
        witnessesInput: [],
      });
    }
  }

  handlePhoneChange = (index, phone, name) => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput;
    holder[index].phoneNumbers[0][name] = formatPhoneNumber(phone);
    this.setState({
      witnessesInput: holder,
    });
    onChange("witnessesInput", witnessesInput);
  };

  handleAddWitness = () => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput;
    holder.push({
      id: "",
      firstName: "",
      lastName: "",
      language: "English",
      phoneNumbers: [{ type: "", number: "" }],
      preferredContactMethod: "",
      trialWitness: "",
    });
    this.setState({
      witnessesInput: holder,
    });
    onChange("witnessesInput", witnessesInput);
  };

  handleWitnessUpdate = (index, event) => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput;
    holder[index][event.target.name] = event.target.value;
    this.setState({
      witnessesInput: holder,
    });
    onChange("witnessesInput", witnessesInput);
  };

  handleLanguageChange = (event, data, index) => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput;
    holder[index][event.target.name] = event.target.value;
    this.setState({
      witnessesInput: holder,
    });
    onChange("witnessesInput", witnessesInput);
  };

  handleContactMethodChange = (data, index) => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput;
    holder[index][data.name] = data.value;
    this.setState({
      witnessesInput: holder,
    });
    onChange("witnessesInput", witnessesInput);
  };

  handleTrialTypeChange = (data, index) => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput;
    holder[index][data.name] = data.value;
    this.setState({
      witnessesInput: holder,
    });
    onChange("witnessesInput", witnessesInput);
  };

  handlePhoneTypeUpdate = (event, data, index) => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput;
    holder[index].phoneNumbers[0][data.name] = data.value;
    this.setState({
      witnessesInput: holder,
    });
    onChange("witnessesInput", witnessesInput);
  };

  handleDeleteWitness = (row) => {
    const { witnessesInput } = this.state;
    const { onChange } = this.props;
    const holder = witnessesInput.filter((witness, index) => index !== row);
    this.setState({ witnessesInput: holder });
    onChange("witnessesInput", holder);
  };

  handleCancel = () => {
    navigate("/app/case");
  };

  upperCase = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const { witnessesInput } = this.state;
    const {
      // phoneTypes: { __type } = {},
      data: { loading },
      edit,
    } = this.props;

    const __type = {
      enumValues: [
        { name: "mobile" },
        { name: "landline" },
        { name: "unknown" },
      ],
    };

    const __contactType = {
      enumValues: [
        { name: "phone" },
        { name: "text message" },
        { name: "unknown" },
        { name: "any" },
      ],
    };

    const __trialType = {
      enumValues: [{ name: " " }, { name: "yes" }, { name: "no" }],
    };

    witnessesInput.forEach((witness) => {
      witness.phoneNumber = formatPhoneNumber;
    });

    return (
      <>
        <Dimmer.Dimmable active={loading}>
          <Form className="attached fluid" autoComplete="off">
            <Header as="h4" color="teal">
              Witnesses
            </Header>
            <Table basic="very" stackable columns={7} size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    First Name {witnessesInput.length > 0 ? "*" : ""}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Last Name {witnessesInput.length > 0 ? "*" : ""}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Trial Witness</Table.HeaderCell>
                  <Table.HeaderCell>
                    Phone Number {witnessesInput.length > 0 ? "*" : ""}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Primary Language {witnessesInput.length > 0 ? "*" : ""}
                  </Table.HeaderCell>
                  {/* {edit && <Table.HeaderCell>Phone Type</Table.HeaderCell>} */}
                  <Table.HeaderCell>
                    Preferred Contact Method{" "}
                    {witnessesInput.length > 0 ? "*" : ""}
                  </Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body className="input-table-body">
                {witnessesInput && witnessesInput.length > 0 ? (
                  witnessesInput.map((witness, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Table.Row key={index}>
                        <Table.Cell>
                          <Form.Input
                            autoComplete="off"
                            name="firstName"
                            fluid
                            placeholder="First Name"
                            value={witness.firstName}
                            onChange={(event) =>
                              this.handleWitnessUpdate(index, event)
                            }
                            error={
                              witness.firstName ? (
                                !validate("firstName", witness.firstName) ? (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                    }}
                                  >
                                    <span>{errors["firstName"].message}</span>
                                  </span>
                                ) : undefined
                              ) : undefined
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Form.Input
                            autoComplete="off"
                            name="lastName"
                            fluid
                            placeholder="Last Name"
                            value={witness.lastName}
                            onChange={(event) =>
                              this.handleWitnessUpdate(index, event)
                            }
                            error={
                              witness.lastName ? (
                                !validate("lastName", witness.lastName) ? (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                    }}
                                  >
                                    <span>{errors["lastName"].message}</span>
                                  </span>
                                ) : undefined
                              ) : undefined
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Dropdown
                            name="trialWitness"
                            fluid
                            placeholder="Trial Witness"
                            selection
                            loading={!(__trialType && __trialType.enumValues)}
                            value={
                              witness.trialWitness ||
                              this.handleTrialTypeChange(
                                {
                                  name: "trialWitness",
                                  value: " ",
                                },
                                index
                              )
                            }
                            options={__trialType.enumValues.map((item) => ({
                              key: item.name,
                              text: this.upperCase(item.name),
                              value: item.name,
                            }))}
                            onChange={(event, data) =>
                              this.handleTrialTypeChange(data, index)
                            }
                          />
                        </Table.Cell>

                        <Table.Cell>
                          <PhoneInput
                            autoComplete="off"
                            placeholder="Enter phone number"
                            value={formatPhoneNumber(
                              witness.phoneNumbers[0].number
                            )}
                            name="number"
                            onChange={(phone) =>
                              this.handlePhoneChange(index, phone, "number")
                            }
                            showCountrySelect={false}
                            country="US"
                            error={
                              witness.phoneNumbers[0].number ? (
                                validate(
                                  "phoneNumberWitness",
                                  witness.phoneNumbers[0].number
                                ) ? undefined : (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                    }}
                                  >
                                    <span>
                                      {errors["phoneNumberWitness"].message}
                                    </span>
                                  </span>
                                )
                              ) : undefined
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <LanguageDropdown
                            name="language"
                            onChange={(event, data) =>
                              this.handleLanguageChange(event, data, index)
                            }
                            value={witness.language || "English"}
                          />
                        </Table.Cell>
                        {/* {edit && (
                          <Table.Cell>
                            <Dropdown
                              name='type'
                              fluid
                              placeholder='Phone Type'
                              selection
                              loading={!(__type && __type.enumValues)}
                              value={witness.phoneNumbers[0].type}
                              options={__type.enumValues.map(item => ({
                                key: item.name,
                                text: this.upperCase(item.name),
                                value: item.name
                              }))}
                              onChange={(event, data) =>
                                this.handlePhoneTypeUpdate(event, data, index)
                              }
                            />
                          </Table.Cell>
                        )} */}
                        <Table.Cell>
                          <Dropdown
                            name="preferredContactMethod"
                            fluid
                            placeholder="Contact Method"
                            selection
                            loading={
                              !(__contactType && __contactType.enumValues)
                            }
                            value={
                              witness.preferredContactMethod ||
                              this.handleContactMethodChange(
                                {
                                  name: "preferredContactMethod",
                                  value: "unknown",
                                },
                                index
                              )
                            }
                            options={__contactType.enumValues.map((item) => ({
                              key: item.name,
                              text: this.upperCase(item.name),
                              value: item.name,
                            }))}
                            onChange={(event, data) =>
                              this.handleContactMethodChange(data, index)
                            }
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <Button
                            compact
                            basic
                            color="red"
                            size="small"
                            onClick={() => this.handleDeleteWitness(index)}
                          >
                            <Icon name="cancel" />
                            Delete
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="7" textAlign="center">
                      {" "}
                      Please add witnesses to the case{" "}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Button
              compact
              basic
              color="teal"
              size="small"
              onClick={this.handleAddWitness}
            >
              <Icon name="plus" /> Add Witness
            </Button>
          </Form>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

CaseFormWitness.propTypes = {
  data: PropTypes.object,
  phoneTypes: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  caseId: PropTypes.string,
};

CaseFormWitness.defaultProps = {
  data: {},
  phoneTypes: [],
  contactTypes: [],
  caseId: null,
};

export default flowRight(
  graphql(ListPhoneTypes, { name: "phoneTypes" }),
  graphql(ListWitnesses, {
    skip: (props) => !props.caseId,
    options: (props) => ({ variables: { caseId: props.caseId } }),
  })
)(CaseFormWitness);
