import * as Axios from "axios";
import { getAccessToken } from "../services/Authentication";

Axios.defaults.headers.common["Content-Type"] = "application/json";
let initialized = false;

const ready = () => {
  if (!initialized) {
    throw new Error("you must initialize the service first with API.init()");
  }
};

const Proxy = {
  reset: async (email) => {
    if (!initialized) {
      throw new Error();
    }
    const data = { email: email };
    const url = `${process.env.REST_API_URL}/proxy/reset`;
    const res = await Axios.default.post(url, data);
    return res.data; 
  },
};

export const API = {
  Proxy: Proxy,

  init: async () => {
    const token = await getAccessToken();
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    initialized = true;
  },
};
