import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";

class ReopenModal extends Component {
  state = {
    open: false,
  };

  show = (size) => () => this.setState({ size, open: true });

  close = () => this.setState({ open: false });

  handlePositive = () => {
    const { onChangePositive } = this.props;
    onChangePositive();
    this.setState({
      open: false,
    });
  };

  handleNegative = () => {
    const { onChangeNegative } = this.props;
    onChangeNegative();
    this.setState({
      open: false,
    });
  };

  render() {
    const { open, size } = this.state;
    const {
      title,
      type,
      content,
      onChangeNegative,
      name,
      disabled,
      loading,
      color,
      basic,
    } = this.props;

    return (
      <>
        <Button
          onClick={this.show("tiny")}
          loading={loading}
          compact
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          size="small"
          color={type === "Reopen Case" ? "green" : null}
          disabled={disabled}
          basic={basic || false}
        >
          {type === "Reopen Case" ? <Icon name="open folder" /> : null}
          {type}
        </Button>

        <Modal size={size} open={open} onClose={this.close}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>{content}</Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={onChangeNegative ? this.handleNegative : this.close}
            >
              No
            </Button>
            <Button positive onClick={this.handlePositive}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
ReopenModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onChangePositive: PropTypes.func.isRequired,
  name: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  basic: PropTypes.bool,
  onChangeNegative: PropTypes.func,
};

ReopenModal.defaultProps = {
  onChangeNegative: null,
  loading: false,
  name: null,
  color: null,
  basic: false,
};

export default ReopenModal;
