import gql from 'graphql-tag';

const WitnessFragment = gql`
  fragment WitnessFragment on Witness {
    caseId
    id
    updatedAt
    createdAt
    firstName
    language
    lastName
    phoneNumbers {
      type
      number
    }
    preferredContactMethod
    trialWitness
  }
`;

const GetWitness = gql`
  query($caseId: ID!, $id: ID!) {
    getWitness(caseId: $caseId, id: $id) {
      ...WitnessFragment
    }
  }
  ${WitnessFragment}
`;

const ListWitnesses = gql`
  query($caseId: ID!) {
    listWitnesses(caseId: $caseId) {
      items {
        ...WitnessFragment
      }
      nextToken
    }
  }
  ${WitnessFragment}
`;

const ListPhoneTypes = gql`
  query {
    __type(name: "PhoneType") {
      enumValues {
        name
      }
    }
  }
`;

// const ListPreferredContactMethods = gql`
//     query {
//         __contactType(name: "ContactMethods") {
//             enumValues {
//                 name
//             }
//         }
//     }
// `;

const CreateWitness = gql`
  mutation($caseId: ID!, $witness: WitnessInput!) {
    createWitness(caseId: $caseId, witness: $witness) {
      ...WitnessFragment
    }
  }
  ${WitnessFragment}
`;

const DeleteWitness = gql`
  mutation($caseId: ID!, $id: ID!) {
    deleteWitness(caseId: $caseId, id: $id) {
      ...WitnessFragment
    }
  }
  ${WitnessFragment}
`;

const UpdateWitness = gql`
  mutation($caseId: ID!, $id: ID!, $witness: WitnessInput!) {
    updateWitness(caseId: $caseId, id: $id, witness: $witness) {
      ...WitnessFragment
    }
  }
  ${WitnessFragment}
`;

const BatchUpdateWitnesses = gql`
  mutation($caseId: ID!, $witnessesInput: [WitnessInput!]!) {
    batchUpdateWitnesses(caseId: $caseId, witnesses: $witnessesInput) {
      items {
        ...WitnessFragment
      }
      nextToken
    }
  }
  ${WitnessFragment}
`;

const OnWitnessSubscription = gql`
  subscription($caseId: ID!) {
    onCreateWitness(caseId: $caseId) {
      ...WitnessFragment
    }
    onDeleteWitness(caseId: $caseId) {
      ...WitnessFragment
    }
    onUpdateWitness(caseId: $caseId) {
      ...WitnessFragment
    }
  }
  ${WitnessFragment}
`;

export {
  GetWitness,
  ListWitnesses,
  ListPhoneTypes,
  // ListPreferredContactMethods,
  CreateWitness,
  DeleteWitness,
  UpdateWitness,
  BatchUpdateWitnesses,
  OnWitnessSubscription
};
