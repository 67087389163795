import React, { Component } from "react";
import PropTypes from "prop-types";

import { graphql, withApollo } from "react-apollo";
import { flowRight } from "lodash";
import { DateInput } from "semantic-ui-calendar-react";

import moment from "moment";

import { Dimmer, Form, Header, Loader, Message } from "semantic-ui-react";
import { GetCase } from "./CaseApi";
import { errors, validate } from "../models/FieldValidator.js";

class CaseFormDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      caseInput: {},
    };
    this.datePickerRef = null;
  }

  componentDidMount() {
    const {
      data: { getCase },
    } = this.props;
    if (getCase) {
      this.setStateOnLoad(getCase);
    } else {
      let cachedState = sessionStorage.getItem("create_case_state");
      if (cachedState) {
        cachedState = JSON.parse(cachedState);
        this.setStateOnLoad(cachedState.caseInput);
      }
    }
  }

  setStateOnLoad(caseInput) {
    // eslint-disable-next-line react/no-did-update-set-state
    const { onChange } = this.props;
    this.setState({
      caseInput: caseInput,
    });
    onChange("caseInput", caseInput);
    // onIdentifiersChange({
    //   name: name,
    //   newValue: null,
    //   oldValue: this.state.caseInput[name],
    // });
  }

  componentDidUpdate(prevProps, newProps) {
    const {
      data: { getCase },
      onChange,
      caseId,
    } = this.props;
    const { caseInput } = this.state;
    if (caseId && getCase && [...getCase.id] && !caseInput.id) {
      this.setStateOnLoad(getCase);
    }
    if (prevProps.caseId && !caseId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        error: null,
        caseInput: {
          caseNumber: " ",
          matterNumber: " ",
          arraignmentDate: "",
          indictmentNumber: " ",
          defendantLastName: " ",
          defendantFirstName: " ",
        },
      });
    }
  }

  componentWillUnmount() {
    const { client } = this.props;
    client.cache.reset();
    this.setState(null);
  }

  handleOnChange = (event, { name, value }) => {
    const { caseInput } = this.state;
    const { onChange } = this.props;

    // send these changes to the parent so that we can deal with the previous
    // value of certain fields prior to saving the data
    // this.props.onIdentifiersChange({
    //   name: name,
    //   newValue: value,
    //   oldValue: this.state.caseInput[name],
    // });

    if (name) {
      // extra formatting checks to handle date entry
      if (name === "arraignmentDate") {
        const date = moment(value, "MM/DD/YYYY", true);
        if (date.isValid()) {
          this.datePickerRef.closePopup();
        }
      }
      caseInput[name] = value;
    } else {
      caseInput[event.target.name] = event.target.value;
    }
    this.setStateOnLoad(caseInput);
  };

  setDatePickerRef = (elem) => {
    this.datePickerRef = elem;
  };

  render() {
    const { error, caseInput } = this.state;
    const {
      data: { loading },
      caseId,
    } = this.props;

    return (
      <>
        <Dimmer.Dimmable active={loading}>
          <Form className="attached fluid" autoComplete="off" size="small">
            {error && (
              <Message negative>
                <p>{error.message}</p>
              </Message>
            )}
            <Header as="h5">
              {caseId
                ? "Please change the information on this page to edit the case."
                : "Please fill out the information listed on this page to initiate the case."}
              <div
                style={{
                  color: "rgba(0,0,0,0.38)",
                  fontSize: "12px",
                  paddingTop: "5px",
                }}
              >
                * indicates required
              </div>
            </Header>
            <Header color="teal" as="h4">
              Case Details
            </Header>
            <Form.Group widths={3} autoComplete="off">
              <Form.Field autoComplete="off">
                <Form.Input
                  autoComplete="off"
                  label="Arrest ID *"
                  name="caseNumber"
                  onChange={this.handleOnChange}
                  placeholder="Arrest ID"
                  value={caseInput.caseNumber}
                  error={
                    caseInput.caseNumber ? (
                      !validate("caseNumber", caseInput.caseNumber) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <span>{errors["caseNumber"].message}</span>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  autoComplete="off"
                  label="Docket Number"
                  name="matterNumber"
                  onChange={this.handleOnChange}
                  placeholder="Docket Number"
                  value={caseInput.matterNumber || null}
                  error={
                    caseInput.matterNumber ? (
                      !validate("matterNumber", caseInput.matterNumber) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <span>{errors["matterNumber"].message}</span>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  autoComplete="off"
                  label="Indictment Number"
                  name="indictmentNumber"
                  onChange={this.handleOnChange}
                  placeholder="Indictment Number"
                  value={caseInput.indictmentNumber || null}
                  error={
                    caseInput.indictmentNumber ? (
                      !validate(
                        "indictmentNumber",
                        caseInput.indictmentNumber
                      ) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <span>{errors["indictmentNumber"].message}</span>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <DateInput
                  ref={this.setDatePickerRef}
                  animation={null}
                  autoComplete="off"
                  iconPosition="left"
                  name="arraignmentDate"
                  dateFormat="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  label="Arraignment *"
                  popupPosition="bottom right"
                  onChange={this.handleOnChange}
                  value={caseInput.arraignmentDate}
                  error={
                    caseInput.arraignmentDate ? (
                      !validate(
                        "arraignmentDate",
                        caseInput.arraignmentDate
                      ) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <span>{errors["arraignmentDate"].message}</span>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2} autoComplete="off">
              <Form.Field autoComplete="off">
                <Form.Input
                  autoComplete="off"
                  label="Defendant First Name *"
                  name="defendantFirstName"
                  onChange={this.handleOnChange}
                  placeholder="Defendant First Name"
                  value={caseInput.defendantFirstName}
                  error={
                    caseInput.defendantFirstName ? (
                      !validate(
                        "defendantFirstName",
                        caseInput.defendantFirstName
                      ) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <span>{errors["defendantFirstName"].message}</span>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
              <Form.Field autoComplete={caseInput.defendantFirstName}>
                <Form.Input
                  autoComplete="off"
                  label="Defendant Last Name *"
                  name="defendantLastName"
                  onChange={this.handleOnChange}
                  placeholder="Defendant Last Name"
                  value={caseInput.defendantLastName}
                  error={
                    caseInput.defendantLastName ? (
                      !validate(
                        "defendantLastName",
                        caseInput.defendantLastName
                      ) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "11px",
                          }}
                        >
                          <span>{errors["defendantLastName"].message}</span>
                        </span>
                      ) : undefined
                    ) : undefined
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

CaseFormDetails.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  caseId: PropTypes.string,
};

CaseFormDetails.defaultProps = {
  data: {},
  caseId: null,
};

export default flowRight(
  withApollo,
  graphql(GetCase, {
    skip: (props) => !props.caseId,
    options: (props) => ({ variables: { id: props.caseId } }),
  })
)(CaseFormDetails);
