import gql from 'graphql-tag';

const ProsecutorFragment = gql`
  fragment ProsecutorFragment on Prosecutor {
    id
    createdAt
    enabled
    updatedAt
    email
    firstName
    group
    lastName
    status
    phoneNumber
    jurisdiction
  }
`;

const GetProsecutor = gql`
  query($id: ID!) {
    getProsecutor(id: $id) {
      ...ProsecutorFragment
    }
  }
  ${ProsecutorFragment}
`;

const ListProsecutors = gql`
  query($group: String, $jurisdiction: String) {
    listProsecutors(group: $group, jurisdiction: $jurisdiction) {
      items {
        id
        createdAt
        enabled
        updatedAt
        email
        firstName
        lastName
        status
        phoneNumber
        jurisdiction
      }
      nextToken
    }
  }
`;

const UpdateCaseProsecutors = gql`
  mutation($caseId: ID!, $prosecutorIds: [ID!]!) {
    updateCaseProsecutors(caseId: $caseId, prosecutorIds: $prosecutorIds) {
      items {
        active
        prosecutor {
          id
          firstName
          lastName
          email
          phoneNumber
          jurisdiction
        }
      }
    }
  }
`;

const ListProsecutorGroups = gql`
  query {
    __type(name: "ProsecutionGroup") {
      name
      enumValues {
        name
      }
    }
  }
`;

const CreateProsecutor = gql`
  mutation($prosecutor: ProsecutorInput!) {
    createProsecutor(prosecutor: $prosecutor) {
      ...ProsecutorFragment
    }
  }
  ${ProsecutorFragment}
`;

const UpdateProsecutor = gql`
  mutation($id: ID!, $prosecutor: ProsecutorInput!) {
    updateProsecutor(id: $id, prosecutor: $prosecutor) {
      ...ProsecutorFragment
    }
  }
  ${ProsecutorFragment}
`;

const DisableProsecutor = gql`
  mutation($id: ID!) {
    disableProsecutor(id: $id) {
      ...ProsecutorFragment
    }
  }
  ${ProsecutorFragment}
`;

const EnableProsecutor = gql`
  mutation($id: ID!) {
    enableProsecutor(id: $id) {
      ...ProsecutorFragment
    }
  }
  ${ProsecutorFragment}
`;

const OnProsecutorSubscription = gql`
  subscription {
    onCreateProsecutor {
      ...ProsecutorFragment
    }
    onUpdateProsecutor {
      ...ProsecutorFragment
    }
  }
  ${ProsecutorFragment}
`;

export {
  GetProsecutor,
  ListProsecutors,
  CreateProsecutor,
  UpdateProsecutor,
  EnableProsecutor,
  DisableProsecutor,
  ListProsecutorGroups,
  UpdateCaseProsecutors,
  OnProsecutorSubscription
};
