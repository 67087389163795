import gql from 'graphql-tag';

const CaseFragment = gql`
  fragment CaseFragment on Case {
    id
    status
    assigned
    createdAt
    updatedAt
    updatedBy
    caseNumber
    matterNumber
    jurisdiction
    arraignmentDate
    indictmentNumber
    defendantFirstName
    defendantLastName
  }
`;

const GetCase = gql`
  query($id: ID!) {
    getCase(id: $id) {
      ...CaseFragment
    }
  }
  ${CaseFragment}
`;

const GetPhoneNumberType = gql`
  query($numbers: [String!]!) {
    getPhoneNumberType(numbers: $numbers) {
      items {
        number
        type
      }
    }
  }
`;

const ListCase = gql`
  query($filter: ListCaseFilter) {
    listCase(filter: $filter) {
      user {
        email
        enabled
        given_name
        user_status
        family_name
        phone_number
        proxy
        company
        email_sent
        jurisdiction
      }
      items {
        ...CaseFragment
      }
    }
  }
  ${CaseFragment}
`;

const ListCases = gql`
  query($filter: CaseFilter, $limit: Int, $nextToken: String) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...CaseFragment
      }
      nextToken
    }
  }
  ${CaseFragment}
`;

const CreateCase = gql`
  mutation(
    $arraignmentDate: AWSDate!
    $caseNumber: String!
    $defendantFirstName: String!
    $defendantLastName: String!
    $indictmentNumber: String
    $status: CaseStatus
    $matterNumber: String
    $jurisdiction: String
  ) {
    createCase(
      case: {
        arraignmentDate: $arraignmentDate
        caseNumber: $caseNumber
        defendantFirstName: $defendantFirstName
        defendantLastName: $defendantLastName
        indictmentNumber: $indictmentNumber
        status: $status
        matterNumber: $matterNumber
        jurisdiction: $jurisdiction
      }
    ) {
      ...CaseFragment
    }
  }
  ${CaseFragment}
`;

const ListCaseDefenders = gql`
  query($caseId: ID!) {
    listCaseDefenders(caseId: $caseId) {
      items {
        createdAt
        active
        defender {
          id
          firstName
          lastName
          phoneNumber
          email
          company
          enabled
          defense_role
        }
      }
    }
  }
`;

const ListCaseProsecutors = gql`
  query($caseId: ID!) {
    listCaseProsecutors(caseId: $caseId) {
      items {
        active
        createdAt
        prosecutor {
          id
          firstName
          lastName
          phoneNumber
          email
          enabled
          jurisdiction
        }
      }
    }
  }
`;

const ListDefenderCases = gql`
  query($defenderId: ID!) {
    listDefenderCases(defenderId: $defenderId) {
      items {
        # active on the case, assignment status
        active
        case {
          ...CaseFragment
        }
        defender {
          # account status in cognito
          enabled
        }
      }
    }
  }
  ${CaseFragment}
`;

const ListProsecutorCases = gql`
  query($prosecutorId: ID!) {
    listProsecutorCases(prosecutorId: $prosecutorId) {
      items {
        active
        case {
          ...CaseFragment
        }
        prosecutor {
          enabled
        }
      }
    }
  }
  ${CaseFragment}
`;

const DeleteCase = gql`
  mutation($id: ID!) {
    deleteCase(id: $id) {
      ...CaseFragment
    }
  }
  ${CaseFragment}
`;

const UpdateCase = gql`
  mutation(
    $id: ID!
    $arraignmentDate: AWSDate
    $caseNumber: String
    $defendantFirstName: String
    $defendantLastName: String
    $indictmentNumber: String
    $status: CaseStatus
    $matterNumber: String
    $jurisdiction: String
  ) {
    updateCase(
      id: $id
      case: {
        arraignmentDate: $arraignmentDate
        caseNumber: $caseNumber
        defendantFirstName: $defendantFirstName
        defendantLastName: $defendantLastName
        indictmentNumber: $indictmentNumber
        status: $status
        matterNumber: $matterNumber
        jurisdiction: $jurisdiction
      }
    ) {
      ...CaseFragment
    }
  }
  ${CaseFragment}
`;

const OnCaseSubscription = gql`
  subscription {
    onCreateCase {
      ...CaseFragment
    }
    onDeleteCase {
      ...CaseFragment
    }
    onUpdateCase {
      ...CaseFragment
    }
  }
  ${CaseFragment}
`;

export {
  GetCase,
  GetPhoneNumberType,
  ListCase,
  ListCases,
  ListCaseDefenders,
  ListCaseProsecutors,
  ListProsecutorCases,
  ListDefenderCases,
  CreateCase,
  DeleteCase,
  UpdateCase,
  OnCaseSubscription,
};
