import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'react-apollo';
import { Dimmer, Loader, Message, Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { ListCaseProsecutors } from '../case/CaseApi';
import { navigate } from '../../services/RouteState';
import { formatPhoneNumber } from 'react-phone-number-input';

const ProsecutorCaseTable = ({ data: { error, loading, listCaseProsecutors } }) => {
  let prosecutors = listCaseProsecutors && listCaseProsecutors.items || []
  prosecutors = prosecutors.sort((a, b) => {
    return `${a.prosecutor.lastName}, ${a.prosecutor.firstName}`.localeCompare(`${b.prosecutor.lastName}, ${b.prosecutor.firstName}`)
  });

  return (<>
    {error && (
      <Message negative>
        <p>{error.message}</p>
      </Message>
    )}
    <Dimmer.Dimmable dimmed={loading}>
      <Table basic="very" columns={5}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Added to Case</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Assignment Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {prosecutors && prosecutors.length > 0 ? (
            [...prosecutors].map(item => (
              <Table.Row key={item.prosecutor.id}>
                <Table.Cell
                  style={{
                    color: '#2185D0',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/app/prosecutor/view', {
                      state: {
                        prosecutorId: item.prosecutor.id,
                        enabled: item.prosecutor.enabled,
                      },
                    });
                  }}
                >
                  {item.prosecutor.lastName}
                  {', '}
                  {item.prosecutor.firstName}
                </Table.Cell>
                <Table.Cell>
                  {moment(item.createdAt).format('MM/DD/YYYY')}
                </Table.Cell>
                <Table.Cell
                  style={{
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {item.prosecutor.email}
                </Table.Cell>
                <Table.Cell>
                  {formatPhoneNumber(item.prosecutor.phoneNumber)}
                </Table.Cell>
                <Table.Cell>
                  {item.active ? (
                    item.prosecutor.enabled ? (
                      <span>
                        {' '}
                        <Icon name="check" color="green" />
                        Assigned to Case{' '}
                      </span>
                    ) : (
                        <span>
                          {' '}
                          <Icon name="ban" color="red" />
                          Assigned to Case [DISABLED USER]{' '}
                        </span>
                      )
                  ) : (
                      <span>
                        {' '}
                        <Icon name="close" color="red" />
                        Removed from Case{' '}
                      </span>
                    )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
              <Table.Row>
                <Table.Cell colSpan="5" textAlign="center">
                  {' '}
                  No prosecutors available{' '}
                </Table.Cell>
              </Table.Row>
            )}
        </Table.Body>
      </Table>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
    </Dimmer.Dimmable>
  </>)
};

ProsecutorCaseTable.propTypes = {
  data: PropTypes.object,
};

ProsecutorCaseTable.defaultProps = {
  data: {},
};

export default graphql(ListCaseProsecutors, {
  options: props => ({
    variables: {
      caseId: props.caseId,
    },
  }),
})(ProsecutorCaseTable);
