import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { Dimmer, Icon, Loader, Message, Table } from 'semantic-ui-react';
import moment from 'moment';

import { ListCaseDefenders } from '../case/CaseApi';
import { navigate } from '../../services/RouteState';
import { formatPhoneNumber } from 'react-phone-number-input';

function log(obj) {
  //console.log(obj);
  return true;
}

const DefenderCaseTable = ({ data: { error, loading, listCaseDefenders } }) => {
  let attorneys = listCaseDefenders && listCaseDefenders.items || []
  attorneys = attorneys.sort((a, b) => {
    return `${a.defender.lastName}, ${a.defender.firstName}`.localeCompare(`${b.defender.lastName}, ${b.defender.firstName}`)
  });

  return (<>
    {error && (
      <Message negative>
        <p>{error.message}</p>
      </Message>
    )}
    <Dimmer.Dimmable dimmed={loading}>
      <Table basic="very" stackable columns={6}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Added to Case</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Assignment Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {attorneys.length > 0 ? (
            [...attorneys].map(item => (
              <Table.Row key={item.defender.id}>
                <Table.Cell
                  style={{
                    color: '#2185D0',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/app/defender/view', {
                      state: {
                        defenderId: item.defender.id, active: item.defender.enabled
                      },
                    });
                  }}
                >
                  {item.defender.lastName}, {item.defender.firstName}
                </Table.Cell>
                <Table.Cell>
                  {moment(item.createdAt).format('MM/DD/YYYY')}
                </Table.Cell>
                <Table.Cell>{item.defender.defense_role || '-'}</Table.Cell>
                <Table.Cell
                  style={{
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap'
                  }}>
                  {item.defender.email}
                </Table.Cell>
                <Table.Cell>{formatPhoneNumber(item.defender.phoneNumber)}</Table.Cell>
                <Table.Cell>
                  {item.active ? (
                    item.defender.enabled ? (
                      <span>
                        {' '}
                        <Icon name="check" color="green" />
                        Assigned to Case{' '}
                      </span>
                    ) : (
                        <span>
                          {' '}
                          <Icon name="ban" color="red" />
                          Assigned to Case [DISABLED USER]{' '}
                        </span>
                      )
                  ) : (
                      <span>
                        {' '}
                        <Icon name="close" color="red" />
                        Removed from Case{' '}
                      </span>
                    )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
              <Table.Row>
                <Table.Cell colSpan="6" textAlign="center">
                  {' '}
                  No defense team member available{' '}
                </Table.Cell>
              </Table.Row>
            )}
        </Table.Body>
      </Table>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
    </Dimmer.Dimmable>
  </>)
};

DefenderCaseTable.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  caseId: PropTypes.string,
  data: PropTypes.object,
};

DefenderCaseTable.defaultProps = {
  data: {},
  caseId: null,
};

export default graphql(ListCaseDefenders, {
  options: props => ({
    variables: {
      caseId: props.caseId,
    },
  }),
})(DefenderCaseTable);
