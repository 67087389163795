import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphqlMutation } from "aws-appsync-react";
import { navigate } from "../../services/RouteState";
import { graphql, withApollo } from "react-apollo";
import { flowRight } from "lodash";
import moment from "moment";
import lodash from "lodash";
import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  Message,
  Segment,
  SearchResults,
} from "semantic-ui-react";
import { isValidPhoneNumber } from "react-phone-number-input";

import CaseFormDefense from "./CaseFormDefense";
import CaseFormDetails from "./CaseFormDetails";
import CaseFormProsecutor from "./CaseFormProsecutor";
import CaseFormWitness from "./CaseFormWitness";
import ConfirmModal from "../modal/ConfirmModal";
import SearchDefendersModal from "../search/modal/SearchDefenders";
import SearchProsecutorsModal from "../search/modal/SearchProsecutors";

import {
  CreateCase,
  UpdateCase,
  ListCases,
  GetPhoneNumberType,
} from "./CaseApi";
import { BatchUpdateWitnesses } from "../witness/WitnessApi";
import { UpdateCaseProsecutors } from "../prosecutors/ProsecutorApi";
import { UpdateCaseDefenders } from "../defender/DefenderApi";
import { errors, validate } from "../models/FieldValidator.js";

import * as _ from "lodash";
import { formatPhoneNumberIntl } from "react-phone-number-input/commonjs/formatPhoneNumberDefaultMetadata";
import { getUserAttributes } from "../../services/Authentication";

class CaseForm extends Component {
  constructor(props) {
    super(props);
    this.oldCaseNumber = null;
    this.oldMatterNumber = null;
    this.state = {
      edit: false,
      isDirty: false,
      caseInput: {},
      witnessesInput: [],
      defendersInput: [],
      prosecutorsInput: [],
      errorWitness: null,
      errorMessage: null,
      errorDetail: null,
      initialDefendersInput: [],
    };
  }

  async componentWillMount() {
    const {
      location: { state: { caseId } = {} },
    } = this.props;

    const attributes = await getUserAttributes();
    this.setState({
      jurisdiction: attributes.jurisdiction,
    });

    if (caseId) {
      this.setState({
        edit: true,
      });
    }
    console.log("componentWillMount()");
    if (this.state.edit) {
      this.oldCaseNumber = this.state.caseInput.caseNumber;
      this.oldMatterNumber = this.state.caseInput.matterNumber;
    } else if (!this.state.edit) {
      this.oldCaseNumber = undefined;
      this.oldMatterNumber = undefined;
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      path,
      location: { state: { caseId } = {} },
    } = this.props;
    // this should only be fired when a user is currently in a case edit screen and clicks the enter case nav menu item
    if (prevProps.path === "/case/edit" && path === "/case/new") {
      const attributes = await getUserAttributes();
      this.setState({
        edit: false,
        caseInput: {},
        isDirty: false,
        errorDetail: null,
        witnessesInput: [],
        defendersInput: [],
        errorWitness: null,
        errorMessage: null,
        prosecutorsInput: [],
        initialDefendersInput: [],
        jurisdiction: attributes.jurisdiction,
      });
    }
  }

  componentWillUnmount() {
    const { path } = this.props;

    if (path === "/case/new" && !this.state.cancelled) {
      sessionStorage.setItem("create_case_state", JSON.stringify(this.state));
    }
  }

  handleOnChange = (array, data) => {
    // eslint-disable-next-line react/destructuring-assignment
    let newState = this.state[array];
    newState = data;
    this.setState({
      isDirty: true,
      [array]: newState,
    });
  };

  handleCancel = async () => {
    const { caseInput } = this.state;
    if (caseInput.id) {
      navigate("/app/case/view", {
        state: { caseId: caseInput.id },
      });
    } else {
      sessionStorage.removeItem("create_case_state");
      this.setState({ cancelled: true });
      navigate("/app/home", {
        state: { type: "active" },
      });
    }
  };

  //TODO: Must handle all country codes in the future
  formatPhoneNumberIntl = (value) => {
    value = value
      .replace("+1", "")
      .split("")
      .filter((char) => {
        return /\d/.test(char);
      });

    return "+1" + value.join("");
  };

  handleSubmit = async (status) => {
    this.setState({
      loading: true,
      errorWitness: null,
      errorDetail: null,
    });
    const {
      edit,
      witnessesInput,
      prosecutorsInput,
      defendersInput,
      caseInput: {
        id,
        arraignmentDate,
        caseNumber,
        matterNumber,
        indictmentNumber,
        defendantFirstName,
        defendantLastName,
        defenseAttorney,
      },
      jurisdiction,
    } = this.state;
    const {
      createCase,
      updateCase,
      batchUpdateWitnesses,
      updateCaseProsecutors,
      updateCaseDefenders,
      client: { query },
    } = this.props;

    let caseId = null;

    // Format date fields for AWSDate support.
    const formattedDate = moment(arraignmentDate).format("YYYY-MM-DD");

    // lookup phone number type
    let res = await query({
      query: GetPhoneNumberType,
      name: "cases",
      variables: {
        numbers: witnessesInput.map((item) => item.phoneNumbers[0].number),
      },
    });

    const numberTypes = {};
    res.data.getPhoneNumberType.items.forEach((item) => {
      numberTypes[item.number] = item.type;
    });

    witnessesInput.forEach((witness) => {
      const phone = witness.phoneNumbers[0].number;
      const type = numberTypes[phone];
      if (type) {
        witness.phoneNumbers[0].type = type;
      }
    });

    // const caseChanged = this.oldCaseNumber !== caseNumber;
    // const matterChanged = this.oldMatterNumber !== matterNumber;
    // console.log(`editing case: ${edit}`);
    // console.log(`case # changed: ${caseChanged}`);
    // console.log(`  ${this.oldCaseNumber} !== ${caseNumber}`);
    // console.log(`matter changed: ${matterChanged}`);
    // console.log(`  ${this.oldMatterNumber} !== ${matterNumber}`);

    let c = caseNumber.trim().toUpperCase();
    let m = (matterNumber || "").trim().toUpperCase();
    let oc = (this.oldCaseNumber || "").trim().toUpperCase();
    let om = (this.oldMatterNumber || "").trim().toUpperCase();
    res = null;
    res = await query({
      query: ListCases,
      name: "cases",
      variables: {
        filter: {
          jurisdiction: {
            eq: jurisdiction,
          },
        },
      },
    });
    const cases = (res.data.listCases || { items: [] }).items;
    const caseNums = cases.map((item) => item.caseNumber.trim().toUpperCase());
    const matterNums = cases.map((item) =>
      (item.matterNumber || "").trim().toUpperCase()
    );
    try {
      if (c !== oc && caseNums.includes(c)) {
        throw new Error("This Arrest ID is already in the system.");
      }
      if (m !== om && matterNums.includes(m)) {
        throw new Error("This Docket Number is already in the system.");
      }
    } catch (err) {
      this.setState({
        loading: false,
        errorDetail: {
          message: err.message,
        },
      });
      return;
    }

    // validate witness data
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of witnessesInput) {
        let num = item.phoneNumbers[0].number;

        if (!num || num.length === 0) {
          throw new Error("Please add a phone number for all witnesses.");
        }

        if (item.lastName.trim().length === 0) {
          throw new Error("Please enter a valid last name for all witnesses.");
        }

        if (item.firstName.trim().length === 0) {
          throw new Error("Please enter a valid first name for all witnesses.");
        }
        item.phoneNumbers[0].number = this.formatPhoneNumberIntl(num);
      }
    } catch (err) {
      this.setState({
        loading: false,
        errorWitness: {
          message: err.message,
        },
      });
      return;
    }

    // save everything
    try {
      const payload = {
        id,
        arraignmentDate: formattedDate,
        caseNumber: caseNumber.trim(),
        matterNumber: (matterNumber || "").trim(),
        indictmentNumber: (indictmentNumber || "").trim(),
        defendantFirstName: defendantFirstName.trim(),
        defendantLastName: defendantLastName.trim(),
        defenseAttorney,
        status,
        jurisdiction,
      };

      if (edit) {
        const update = await updateCase(payload);
        caseId = update.data.updateCase.id;
      } else {
        const create = await createCase(payload);
        caseId = create.data.createCase.id;
      }

      await updateCaseDefenders({
        caseId,
        defenderIds: defendersInput.map((item) => item.id),
      });

      await updateCaseProsecutors({
        caseId,
        prosecutorIds: prosecutorsInput.map((item) => item.id),
      });

      await batchUpdateWitnesses({
        caseId,
        matterNumber: matterNumber || null,
        witnessesInput,
      });

      this.setState({
        loading: false,
      });

      // after all calls are made, send to case view

      if (!edit) {
        this.setState({
          cancelled: true,
        });
        sessionStorage.removeItem("create_case_state");
      }
      navigate("/app/case/view", {
        state: { caseId },
      });
    } catch (err) {
      this.setState({
        loading: false,
        errorMessage: err.message,
      });
    }
  };

  confirmMessage() {
    const { defendersInput, initialDefendersInput, edit } = this.state;

    if (!edit && defendersInput.length === 0) {
      return "You are about to create the case. \xa0Do you wish to proceed?";
    } else if (!edit && defendersInput.length > 0) {
      return "You are about to create the case and connect the assigned defense team to the witness list. \xa0Do you wish to proceed?";
    } else if (edit) {
      const initialIds = initialDefendersInput.map((item) => item.id);
      const changedIds = defendersInput.map((item) => item.id);

      let idsAdded = false;

      for (let id of changedIds) {
        if (!initialIds.includes(id)) {
          idsAdded = true;
          break;
        }
      }
      if (idsAdded) {
        return "You are about to save changes to the case and connect the newly assigned defense attorneys to the witness list. \xa0Do you wish to proceed?";
      } else {
        return "You are about to save changes to the case. \xa0Do you wish to proceed?";
      }
    }

    return null;
  }

  areWitnessesValid(witnesses) {
    const { edit } = this.state;
    if (witnesses.length === 0) {
      return false;
    }

    for (let witness of witnesses) {
      if (
        !validate("firstName", witness.firstName) ||
        !validate("lastName", witness.lastName) ||
        !validate("phoneNumberWitness", witness.phoneNumbers[0].number) ||
        // (edit && !witness.phoneNumbers[0].type) ||
        !witness.preferredContactMethod
      ) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      isDirty,
      caseInput,
      prosecutorsInput,
      defendersInput,
      witnessesInput,
      witness,
      initialDefendersInput,
      edit,
      errorWitness,
      errorMessage,
      loading,
      errorDetail,
    } = this.state;
    const {
      location: { state: { caseId } = {} },
    } = this.props;

    return (
      <>
        <Grid columns="equal" relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Breadcrumb size="huge">
                {/* <Breadcrumb.Section link onClick={() => navigate('/app/case')}>Prosecution</Breadcrumb.Section> */}
                {/* <Breadcrumb.Divider /> */}
                <Breadcrumb.Section active>
                  {edit ? "Edit Case" : "Create Case"}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <ConfirmModal
                type="Cancel"
                title="Unsaved Changes"
                content={
                  "You are about to exit with unsaved changes.\xa0 Do you wish to proceed without saving?"
                }
                isDirty={isDirty}
                loading={loading}
                onChangePositive={() => this.handleCancel()}
                disabled={false}
                size="small"
              />
              <ConfirmModal
                type={edit ? "Save" : "Submit"}
                title="Save Case"
                loading={loading}
                size="compact"
                disabled={
                  !caseInput.caseNumber ||
                  !caseInput.defendantFirstName ||
                  !validate(
                    "defendantFirstName",
                    caseInput.defendantFirstName
                  ) ||
                  !caseInput.defendantLastName ||
                  !validate("defendantLastName", caseInput.defendantLastName) ||
                  !caseInput.arraignmentDate ||
                  !validate("arraignmentDate", caseInput.arraignmentDate) ||
                  prosecutorsInput.length === 0 ||
                  this.areWitnessesValid(witnessesInput) ||
                  !isDirty
                }
                // content="You are about to send the victim/witness list to the defense. If you cancel, the case data will not be saved. Do you wish to proceed?"
                content={this.confirmMessage()}
                isDirty={isDirty}
                onChangePositive={() => this.handleSubmit("active")}
              />
              <div style={{ paddingBottom: "17.5px" }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment>
          <CaseFormDetails caseId={caseId} onChange={this.handleOnChange} />
          {errorDetail && (
            <Message negative>
              <p>{errorDetail.message}</p>
            </Message>
          )}
        </Segment>

        <Segment>
          <CaseFormProsecutor
            caseId={caseId}
            onChange={this.handleOnChange}
            prosecutors={prosecutorsInput}
          />
          {prosecutorsInput.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "30px 0px 15px 0px",
                fontSize: "20px",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Please add prosecutors to the case
            </div>
          )}

          <SearchProsecutorsModal
            caseId={caseId}
            onChange={this.handleOnChange}
            prosecutors={prosecutorsInput}
          />
        </Segment>

        <Segment>
          <CaseFormDefense
            caseId={caseId}
            onChange={this.handleOnChange}
            defenders={defendersInput}
          />
          {defendersInput.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "30px 0px 15px 0px",
                fontSize: "20px",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Please add defense team to the case
            </div>
          )}

          <SearchDefendersModal
            caseId={caseId}
            onChange={this.handleOnChange}
            defenders={defendersInput}
          />
        </Segment>

        <Segment>
          {errorWitness && (
            <Message negative>
              <p>{errorWitness.message}</p>
            </Message>
          )}
          <CaseFormWitness
            caseId={caseId}
            edit={edit}
            onChange={this.handleOnChange}
          />
        </Segment>
        <Divider />
        {errorMessage && (
          <Message negative>
            <p>{errorMessage.message}</p>
          </Message>
        )}
      </>
    );
  }
}

CaseForm.propTypes = {
  location: PropTypes.object,
  createCase: PropTypes.func.isRequired,
  updateCase: PropTypes.func.isRequired,
  batchUpdateWitnesses: PropTypes.func.isRequired,
  updateCaseDefenders: PropTypes.func.isRequired,
  updateCaseProsecutors: PropTypes.func.isRequired,
};

CaseForm.defaultProps = {
  location: {},
};

export default flowRight(
  withApollo,
  graphqlMutation(CreateCase),
  graphqlMutation(UpdateCase),
  graphqlMutation(BatchUpdateWitnesses),
  graphqlMutation(UpdateCaseProsecutors),
  graphqlMutation(UpdateCaseDefenders)
)(CaseForm);
